import * as io from 'io-ts';
import {BaseServerTypeCodec} from './BaseServerType';

const UserInfo = io.type({
  email: io.string,
  name: io.string,
  surname: io.string,
});

const User = io.exact(
  io.intersection([
    BaseServerTypeCodec,
    io.type({
      ...UserInfo.props,
      username: io.string,
    }),
    io.partial({
      UserSites: io.array(
        io.type({
          SiteId: io.number,
        }),
      ),
    }),
  ]),
);

export const UserCodec = User;

export type User = io.TypeOf<typeof User>;
