import {useEffect, useState} from 'react';
import {breakpoints, IBreakPoints} from '../utils/StyleUtility';

// This is hook can be used to determine if the user is on a mobile screen
export function useMobile() {
  return useBreakPoint('md');
}

function checkBreakPoint(breakPoint: keyof IBreakPoints) {
  return window.innerWidth < breakpoints[breakPoint];
}

// This is hook can be used to determine if the user is under a screen's breakpoint
export function useBreakPoint(breakPoint: keyof IBreakPoints) {
  const [isBreakPoint, setIsBreakPoint] = useState(checkBreakPoint(breakPoint));

  useEffect(() => {
    function setter() {
      setIsBreakPoint(checkBreakPoint(breakPoint));
    }

    window.addEventListener('resize', setter);
    return () => window.removeEventListener('resize', setter);
  }, [breakPoint]);

  return isBreakPoint;
}

function checkBreakPoints(...breakPoints: (keyof IBreakPoints)[]) {
  return breakPoints
    .filter((bp) => window.innerWidth < breakpoints[bp])
    .sort((bpA, bpB) => breakpoints[bpA] - breakpoints[bpB]);
}

// This is hook can be used to determine in which screen breakpoint we are
export function useBreakPoints(...breakPoints: (keyof IBreakPoints)[]) {
  const [validBreakPoints, setValidBreakPoints] = useState<(keyof IBreakPoints)[]>([]);

  useEffect(() => {
    function setter() {
      setValidBreakPoints(checkBreakPoints(...breakPoints));
    }

    setter();

    window.addEventListener('resize', setter);
    return () => window.removeEventListener('resize', setter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, breakPoints);

  return validBreakPoints;
}
