import * as io from 'io-ts';
import {ResponseBodyErrorCodec} from './ResponseBodyError';

export const ResponseBodyBaseCodec = io.type({
  status: io.boolean,
  error: io.union([ResponseBodyErrorCodec, io.null]),
});

export type ResponseBodyTypeBase = io.TypeOf<typeof ResponseBodyBaseCodec>;

export const ResponseBody = <C extends io.Mixed>(codec: C) =>
  io.intersection([
    ResponseBodyBaseCodec,
    io.interface({
      // TO DEBUG: remove the union and leave only the codec
      data: io.union([codec, io.null]),
    }),
  ]);

export type ResponseBody<T> = ResponseBodyTypeBase & {data: T | null};
