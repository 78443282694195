/* eslint-disable camelcase */
import * as io from 'io-ts';
import {DateFromISOString} from 'io-ts-types/lib/DateFromISOString';

export const FixedBaseServerType = io.type({
  created_at: DateFromISOString,
  updated_at: DateFromISOString,
  id: io.number,
});

export const PartialBaseServerType = io.partial({
  SiteId: io.union([io.number, io.null]),
  deleted_at: io.union([DateFromISOString, io.null]),
});

const BaseServerType = io.intersection([FixedBaseServerType, PartialBaseServerType]);

export const BaseServerTypeCodec = BaseServerType;
export const PartialBaseServerTypeCodec = io.intersection([
  io.partial({...FixedBaseServerType.props}),
  PartialBaseServerType,
]);

export type BaseServerType = io.TypeOf<typeof BaseServerType>;
