/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {LoginForm, LoginFormValueTypes} from '../components/LoginForm';
import logo from '../assets/2hireLogo.svg';

interface LoginPageProps {
  backgroundImage: string;
  isLoading: boolean;
  isMobile: boolean;
  onLoginSubmit: (values: LoginFormValueTypes[]) => void;
}

export const LoginPage = ({
  backgroundImage,
  isLoading,
  isMobile,
  onLoginSubmit: handleOnLoginSubmit,
}: LoginPageProps) => {
  const handleOnFormSubmit = (e: React.FormEvent, values: LoginFormValueTypes[]) => {
    e.preventDefault();

    handleOnLoginSubmit(values);
  };

  return (
    <LoginPageWrapper>
      <BackgroundImage src={backgroundImage} />
      <LoginPageBody>
        <LoginFormContainer>
          <LogoContainer {...(isMobile && {style: {textAlign: 'center'}})}>
            <LogoImage {...(isMobile && {style: {width: '13rem', height: '4.5rem', marginBottom: '6px'}})} src={logo} />
            <LogoDescription {...(isMobile && {style: {marginBottom: '4rem'}})}>
              Install everything. Everywhere.
            </LogoDescription>
          </LogoContainer>
          <LoginFormStyled isLoading={isLoading} isMobile={isMobile} onFormSubmit={handleOnFormSubmit} />
        </LoginFormContainer>
      </LoginPageBody>
    </LoginPageWrapper>
  );
};

const LoginPageWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const LoginPageBody = styled.div`
  flex: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

const LoginFormContainer = styled.div`
  margin: auto;
`;

const LoginFormStyled = styled(LoginForm)`
  width: 31.5rem;
  height: 41.5rem;
`;

const BackgroundImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
`;

const LogoContainer = styled.div`
  color: white;
  margin-top: auto;
  margin-bottom: 2rem;
`;

const LogoImage = styled.img`
  height: 3rem;
  width: 8rem;
  margin-bottom: 12px;
`;

const LogoDescription = styled.h1`
  font-size: 1rem;
`;
