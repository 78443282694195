import {createSlice, Middleware, PayloadAction} from '@reduxjs/toolkit';
import {LoginFormValueTypes} from '../../components/LoginForm';
import {Service} from '../../models/Service';
import {User} from '../../models/User';
import {RootState} from '../../store';

export type AuthenticationData = Partial<{
  token: string;
  service: Service | null;
  user: User;
}>;

export type AuthenticationDataRememberMe = AuthenticationData & {rememberMe: boolean};

export type AuthenticationSliceState = {
  status: 'idle' | 'loading';
  data: AuthenticationData;
};

export interface IFetchAuthenticationData {
  authData: AuthenticationData;
  values: LoginFormValueTypes[];
}

export const clearStateMiddleware: Middleware = () => (next) => (action) => {
  if (action.type === 'authentication/clearState') {
    try {
      localStorage.removeItem('app_data');
      sessionStorage.removeItem('app_data');
    } catch (e) {
      console.error('Error destroying authentication data', JSON.stringify(e));
    }
  }
  return next(action);
};

export const setItemToStateMiddleware: Middleware =
  () => (next) => (action: PayloadAction<AuthenticationDataRememberMe>) => {
    if (action.type === 'authentication/setItemToState') {
      try {
        const {service, token, user} = action.payload;
        if (action.payload.rememberMe) {
          try {
            localStorage.setItem('app_data', JSON.stringify({status: 'idle', data: {service, token, user}}));
            document.cookie = `token=${action.payload.token};domain=.2hire.io;path=/;secure;`;
          } catch (e) {
            console.error(JSON.stringify(e));
          }
        } else {
          try {
            sessionStorage.setItem('app_data', JSON.stringify({status: 'idle', data: {service, token, user}}));
            document.cookie = `token=${action.payload.token};domain=.2hire.io;path=/;secure;`;
          } catch (e) {
            console.error(JSON.stringify(e));
          }
        }
      } catch (e) {
        console.error('Error saving authentication data', JSON.stringify(e));
      }
    }
    return next(action);
  };

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {status: 'idle', data: {}} as AuthenticationSliceState,
  reducers: {
    setItemToState(state, action: PayloadAction<AuthenticationDataRememberMe>) {
      state.data = {...state.data, ...action.payload};
    },
    setStatusToLoading(state) {
      state.status = 'loading';
    },
    setStatusToIdle(state) {
      state.status = 'idle';
    },
    clearState(state) {
      state.status = 'idle';
      state.data = {};
    },
  },
});

export const {setItemToState, setStatusToLoading, setStatusToIdle, clearState} = authenticationSlice.actions;

export const getToken = (state: RootState) => state.authentication.data.token;
export const getStatus = (state: RootState) => state.authentication.status;

export default authenticationSlice.reducer;
