/* eslint-disable max-len */
import {PropsWithChildren} from 'react';
import styled, {keyframes} from 'styled-components';

interface SvgIconProps {
  className?: string;
}

export const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

export const Plus = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M14.41,6.91H8.09V.59A.59.59,0,0,0,7.5,0a.6.6,0,0,0-.59.59h0V6.91H.59A.59.59,0,0,0,0,7.5a.6.6,0,0,0,.59.59H6.91v6.32A.59.59,0,0,0,7.5,15a.6.6,0,0,0,.59-.59V8.09h6.32A.59.59,0,0,0,15,7.5a.6.6,0,0,0-.59-.59Z" />
  </Svg>
);

export const Minus = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M14.41,6.91H.59A.59.59,0,0,0,0,7.5a.6.6,0,0,0,.59.59H14.41A.59.59,0,0,0,15,7.5a.6.6,0,0,0-.59-.59Z" />
  </Svg>
);

export const Edit = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <polygon points="0 11.84 0 15 3.16 15 12.5 5.66 9.34 2.5 0 11.84" />
      <path d="M14.75,2.24l-2-2a.85.85,0,0,0-1.2,0L10,1.81,13.19,5l1.56-1.56A.85.85,0,0,0,14.75,2.24Z" />
    </g>
  </Svg>
);

export const Calendar = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M13.24,1.17h-.7V0H11.37V1.17H3.63V0H2.46V1.17h-.7A1.76,1.76,0,0,0,0,2.93V13.24A1.76,1.76,0,0,0,1.76,15H13.24A1.76,1.76,0,0,0,15,13.24V2.93A1.76,1.76,0,0,0,13.24,1.17Zm.59,12.07a.58.58,0,0,1-.59.59H1.76a.58.58,0,0,1-.59-.59V5.51H13.83Zm0-8.9H1.17V2.93a.59.59,0,0,1,.59-.59h.7V3.52H3.63V2.34h7.74V3.52h1.17V2.34h.7a.59.59,0,0,1,.59.59Z" />
      <g>
        <rect height="1.25" width="1.25" x="2.51" y="6.88" />
        <rect height="1.25" width="1.25" x="4.68" y="6.88" />
        <rect height="1.25" width="1.25" x="6.87" y="6.88" />
        <rect height="1.25" width="1.25" x="9.06" y="6.88" />
        <rect height="1.25" width="1.25" x="11.24" y="6.88" />
        <rect height="1.25" width="1.25" x="2.51" y="9.06" />
        <rect height="1.25" width="1.25" x="4.71" y="9.06" />
        <rect height="1.25" width="1.25" x="6.89" y="9.06" />
        <rect height="1.25" width="1.25" x="9.07" y="9.06" />
        <rect height="1.25" width="1.25" x="2.51" y="11.25" />
        <rect height="1.25" width="1.25" x="4.7" y="11.25" />
        <rect height="1.25" width="1.25" x="6.88" y="11.25" />
        <rect height="1.25" width="1.25" x="9.06" y="11.25" />
        <rect height="1.25" width="1.25" x="11.24" y="9.06" />
      </g>
    </g>
  </Svg>
);

export const Cross = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path
      d="M14.41,6.91H8.09V.59A.59.59,0,0,0,7.5,0a.6.6,0,0,0-.59.59h0V6.91H.59A.59.59,0,0,0,0,7.5a.6.6,0,0,0,.59.59H6.91v6.32A.59.59,0,0,0,7.5,15a.6.6,0,0,0,.59-.59V8.09h6.32A.59.59,0,0,0,15,7.5a.6.6,0,0,0-.59-.59Z"
      transform="rotate(45,7.5,7.5)"
    />
  </Svg>
);

export const Check = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M14.78,2.2a.75.75,0,0,0-1.06,0l-9,9L1.28,7.74a.75.75,0,0,0-1.06,0,.77.77,0,0,0,0,1.07l4,4a.75.75,0,0,0,1.06,0l9.52-9.54A.75.75,0,0,0,14.78,2.2Z" />
  </Svg>
);

export const Bin = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M12.32,1.9H9.72V1.4A1.43,1.43,0,0,0,8.32,0H6.42A1.37,1.37,0,0,0,5,1.4v.5H2.42a1.16,1.16,0,0,0-1.2,1.2V4.7a.47.47,0,0,0,.5.5H2l.4,8.5A1.34,1.34,0,0,0,3.82,15H11a1.35,1.35,0,0,0,1.4-1.3l.4-8.5h.3a.47.47,0,0,0,.5-.5V3.1A1.47,1.47,0,0,0,12.32,1.9Zm-6.4-.5a.47.47,0,0,1,.5-.5h1.9a.47.47,0,0,1,.5.5v.5H5.92ZM2.12,3.1a.22.22,0,0,1,.2-.2h9.9a.22.22,0,0,1,.2.2V4.3H2V3.1Zm9.3,10.6c0,.3-.2.4-.5.4H3.72a.46.46,0,0,1-.5-.4l-.3-8.5h8.9Z" />
      <path d="M7.32,13.2a.47.47,0,0,0,.5-.5V6.6a.47.47,0,0,0-.5-.5c-.3,0-.4.2-.4.5v6.1A.46.46,0,0,0,7.32,13.2Z" />
      <path d="M9.72,13.2a.54.54,0,0,0,.5-.5V6.6a.5.5,0,0,0-1,0v6.1A.47.47,0,0,0,9.72,13.2Z" />
      <path d="M5,13.2a.47.47,0,0,0,.5-.5V6.6A.47.47,0,0,0,5,6.1a.47.47,0,0,0-.5.5v6.1A.47.47,0,0,0,5,13.2Z" />
    </g>
  </Svg>
);

export const ImagePlaceholder = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M13.59,0H1.41A1.41,1.41,0,0,0,0,1.41V13.59A1.41,1.41,0,0,0,1.41,15H13.59A1.41,1.41,0,0,0,15,13.59V1.41A1.41,1.41,0,0,0,13.59,0Zm.47,13.59a.47.47,0,0,1-.47.47H1.41a.47.47,0,0,1-.47-.47V1.41A.47.47,0,0,1,1.41.94H13.59a.47.47,0,0,1,.47.47Z" />
      <path d="M10.2,8a.46.46,0,0,0-.67,0L8.44,9.07,6.41,7a.48.48,0,0,0-.67,0L2.42,10.37a.49.49,0,0,0,0,.68.5.5,0,0,0,.33.14h9.48a.48.48,0,0,0,.47-.48.52.52,0,0,0-.14-.35Z" />
      <circle cx="8.77" cy="5.21" r="1.4" />
    </g>
  </Svg>
);

export const Download = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M10.15,6.51a.35.35,0,0,0-.31-.26H8.5V.45C8.5.2,8.35,0,8.17,0H6.83C6.65,0,6.5.2,6.5.45v5.8H5.16a.35.35,0,0,0-.31.26A.57.57,0,0,0,4.91,7l2.34,3.57a.28.28,0,0,0,.5,0L10.1,7A.61.61,0,0,0,10.15,6.51Z" />
      <path d="M13,9.64v3.22H2V9.64H0v4.29A1,1,0,0,0,1,15H14a1,1,0,0,0,1-1.07V9.64Z" />
    </g>
  </Svg>
);

export const Upload = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path
        d="M10.15,6.51a.35.35,0,0,0-.31-.26H8.5V.45C8.5.2,8.35,0,8.17,0H6.83C6.65,0,6.5.2,6.5.45v5.8H5.16a.35.35,0,0,0-.31.26A.57.57,0,0,0,4.91,7l2.34,3.57a.28.28,0,0,0,.5,0L10.1,7A.61.61,0,0,0,10.15,6.51Z"
        transform="rotate(180, 7.5, 5.3576)"
      />
      <path d="M13,9.64v3.22H2V9.64H0v4.29A1,1,0,0,0,1,15H14a1,1,0,0,0,1-1.07V9.64Z" />
    </g>
  </Svg>
);

export const Refresh = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} data-test-id="icon-refresh" height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M12.09,8.44A4.64,4.64,0,0,1,7.5,13.12a4.69,4.69,0,0,1-.92-9.27V5.63L9.34,2.81,6.58,0V2A6.53,6.53,0,0,0,1.07,8.44,6.51,6.51,0,0,0,7.5,15a6.51,6.51,0,0,0,6.43-6.56Z" />
  </Svg>
);

export const Loader = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M7.5,4.78a.31.31,0,0,1-.31-.31V.33a.31.31,0,1,1,.62,0V4.47A.31.31,0,0,1,7.5,4.78Z" id="path_12" />
      <path
        d="M6,5.19A.32.32,0,0,1,5.71,5L3.64,1.44A.31.31,0,0,1,3.75,1a.32.32,0,0,1,.43.12L6.25,4.72a.31.31,0,0,1-.11.43A.32.32,0,0,1,6,5.19Z"
        id="path_11"
      />
      <path
        d="M4.87,6.3a.29.29,0,0,1-.16,0L1.12,4.19A.31.31,0,0,1,1,3.76a.3.3,0,0,1,.42-.11L5,5.72a.31.31,0,0,1,.12.42A.33.33,0,0,1,4.87,6.3Z"
        id="path_10"
      />
      <path d="M4.46,7.82H.31a.31.31,0,1,1,0-.62H4.46a.31.31,0,0,1,0,.62Z" id="path_9" />
      <path
        d="M1.28,11.41A.29.29,0,0,1,1,11.26a.31.31,0,0,1,.11-.43L4.71,8.76a.31.31,0,0,1,.43.11A.32.32,0,0,1,5,9.3L1.43,11.37A.28.28,0,0,1,1.28,11.41Z"
        id="path_8"
      />
      <path
        d="M3.91,14.05A.3.3,0,0,1,3.75,14a.3.3,0,0,1-.11-.42L5.71,10a.32.32,0,0,1,.43-.11.31.31,0,0,1,.11.43L4.18,13.89A.33.33,0,0,1,3.91,14.05Z"
        id="path_7"
      />
      <path d="M7.5,15a.31.31,0,0,1-.31-.31V10.55a.31.31,0,0,1,.62,0v4.14A.31.31,0,0,1,7.5,15Z" id="path_6" />
      <path
        d="M11.09,14.05a.33.33,0,0,1-.27-.16L8.75,10.3a.31.31,0,0,1,.11-.43.32.32,0,0,1,.43.11l2.07,3.6a.3.3,0,0,1-.11.42A.3.3,0,0,1,11.09,14.05Z"
        id="path_5"
      />
      <path
        d="M13.72,11.41a.28.28,0,0,1-.15,0L10,9.3a.32.32,0,0,1-.12-.43.31.31,0,0,1,.43-.11l3.59,2.07a.31.31,0,0,1,.11.43A.29.29,0,0,1,13.72,11.41Z"
        id="path_4"
      />
      <path d="M14.69,7.82H10.54a.31.31,0,0,1,0-.62h4.15a.31.31,0,0,1,0,.62Z" id="path_3" />
      <path
        d="M10.13,6.3a.33.33,0,0,1-.27-.16A.31.31,0,0,1,10,5.72l3.59-2.07a.3.3,0,0,1,.42.11.31.31,0,0,1-.11.43L10.29,6.26A.29.29,0,0,1,10.13,6.3Z"
        id="path_2"
      />
      <path
        d="M9,5.19a.32.32,0,0,1-.16,0,.31.31,0,0,1-.11-.43l2.07-3.59A.32.32,0,0,1,11.25,1a.31.31,0,0,1,.11.43L9.29,5A.32.32,0,0,1,9,5.19Z"
        id="path_1"
      />
    </g>
  </Svg>
);

Loader.Lighter = styled(Loader)`
  g path {
    &#path_1 {
      fill: hsl(255, 20%, 95%);
    }

    &#path_2 {
      fill: hsl(0, 0%, 92%);
    }

    &#path_3 {
      fill: hsl(0, 0%, 90%);
    }

    &#path_4 {
      fill: hsl(0, 0%, 85%);
    }

    &#path_5 {
      fill: hsl(0, 0%, 80%);
    }

    &#path_6,
    &#path_7,
    &#path_8,
    &#path_9,
    &#path_10,
    &#path_11,
    &#path_12 {
      fill: hsl(0, 0%, 75%);
    }
  }
`;

Loader.Light = styled(Loader)`
  g path {
    &#path_1 {
      fill: hsl(255, 20%, 95%);
    }

    &#path_2 {
      fill: hsl(0, 0%, 88%);
    }

    &#path_3 {
      fill: hsl(0, 0%, 81%);
    }

    &#path_4 {
      fill: hsl(0, 0%, 74%);
    }

    &#path_5 {
      fill: hsl(0, 0%, 67%);
    }

    &#path_6,
    &#path_7,
    &#path_8,
    &#path_9,
    &#path_10,
    &#path_11,
    &#path_12 {
      fill: hsl(0, 0%, 60%);
    }
  }
`;

Loader.Default = styled(Loader)`
  g path {
    fill: #3e88f1;
  }
`;

const rotationAnimation = keyframes`
  0% {
    transform: rotate(0deg)
  }
  
  100% {
    transform: rotate(360deg)
  }
`;

export const withSpinningAnimation = (icon: typeof Loader) => styled(icon)`
  g {
    animation-name: ${rotationAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    animation-timing-function: linear;
  }
`;

export const Clock = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M7.5,0A7.5,7.5,0,1,0,15,7.5,7.52,7.52,0,0,0,7.5,0Zm0,14.06A6.56,6.56,0,1,1,14.06,7.5,6.56,6.56,0,0,1,7.5,14.06Z" />
      <polygon points="8 2.81 7.03 2.81 7.03 7.78 10.09 10.78 10.78 10.11 8 7.38 8 2.81" />
    </g>
  </Svg>
);

export const History = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M8.09,4V7.26l2.13,2.13a.59.59,0,0,1-.83.83L7.09,7.91a.58.58,0,0,1-.18-.41V4A.59.59,0,0,1,8.09,4ZM9.8,0H5.2A5.19,5.19,0,0,0,1.79,1.27V.59A.59.59,0,1,0,.62.59v2.3a.59.59,0,0,0,.58.59H3.51a.59.59,0,0,0,0-1.18H2.4A4.05,4.05,0,0,1,5.2,1.17H9.8a4,4,0,0,1,4,4V9.8a4,4,0,0,1-4,4H5.2a4,4,0,0,1-4-4V7.5a.59.59,0,0,0-.58-.59A.59.59,0,0,0,0,7.5V9.8A5.2,5.2,0,0,0,5.2,15H9.8A5.2,5.2,0,0,0,15,9.8V5.2A5.2,5.2,0,0,0,9.8,0Z" />
  </Svg>
);

export const ChevronLeft = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <polygon points="4.81 7.49 11.05 1.55 11.05 0 3.94 6.71 3.94 8.26 11.05 15 11.05 13.41 4.81 7.49" />
  </Svg>
);

export const ChevronRight = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <polygon
      points="4.81 7.49 11.05 1.55 11.05 0 3.94 6.71 3.94 8.26 11.05 15 11.05 13.41 4.81 7.49"
      transform="rotate(180,7.5,7.5)"
    />
  </Svg>
);

export const ChevronDown = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <polygon
      points="4.81 7.49 11.05 1.55 11.05 0 3.94 6.71 3.94 8.26 11.05 15 11.05 13.41 4.81 7.49"
      transform="rotate(-90,7.5,7.5)"
    />
  </Svg>
);

export const Detail = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M6.86,3.48H8.14V4.7H6.86Zm0,2.22H8.14v5.82H6.86Z" fill="#ffffff" id="fg_path" />
    </g>
  </Svg>
);

export const Search = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M.23,13.66,3.93,10A5.91,5.91,0,0,1,2.45,6.06,6.18,6.18,0,0,1,8.73,0,6.17,6.17,0,0,1,15,6.06a6.17,6.17,0,0,1-6.27,6.05A6.44,6.44,0,0,1,5.13,11L1.41,14.76A.87.87,0,0,1,.82,15a.85.85,0,0,1-.57-.22A.78.78,0,0,1,.23,13.66ZM8.73,1.58A4.57,4.57,0,0,0,4.09,6.06a4.56,4.56,0,0,0,4.64,4.47,4.56,4.56,0,0,0,4.63-4.47A4.56,4.56,0,0,0,8.73,1.58Z" />
  </Svg>
);

export const Gear = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M9.31,9.9a3.39,3.39,0,0,0,0-.64,4.44,4.44,0,0,0,0-.63l1-.82A.66.66,0,0,0,10.51,7L9.84,5.81a.62.62,0,0,0-.78-.27L7.84,6A3.8,3.8,0,0,0,6.77,5.4L6.58,4.08A.63.63,0,0,0,6,3.53H4.63A.63.63,0,0,0,4,4.08L3.82,5.4A3.9,3.9,0,0,0,2.75,6l-1.22-.5a.63.63,0,0,0-.78.27L.08,7a.66.66,0,0,0,.16.82l1,.82a4.44,4.44,0,0,0,0,.63,4.49,4.49,0,0,0,0,.64l-1,.82a.66.66,0,0,0-.16.82l.67,1.18a.63.63,0,0,0,.78.27l1.22-.5a3.8,3.8,0,0,0,1.07.64L4,14.45a.63.63,0,0,0,.62.55H6a.63.63,0,0,0,.62-.55l.19-1.32a4.05,4.05,0,0,0,1.07-.64l1.22.5a.63.63,0,0,0,.78-.27l.66-1.18a.65.65,0,0,0-.15-.82Zm-4,1.59A2.23,2.23,0,1,1,7.48,9.26,2.22,2.22,0,0,1,5.29,11.49Z" />
      <path d="M14.29,3.38a1.43,1.43,0,0,0,0-.29,1.36,1.36,0,0,0,0-.29l.58-.42A.3.3,0,0,0,15,2l-.52-.87A.34.34,0,0,0,14,1l-.66.27a2.78,2.78,0,0,0-.53-.3L12.75.27A.32.32,0,0,0,12.43,0h-1a.32.32,0,0,0-.32.27L11,1a2.43,2.43,0,0,0-.52.3L9.79,1a.33.33,0,0,0-.4.13L8.87,2a.3.3,0,0,0,.08.4l.58.42a2.58,2.58,0,0,0,0,.29,2.85,2.85,0,0,0,0,.29L9,3.8a.3.3,0,0,0-.08.4l.52.86a.32.32,0,0,0,.4.14l.67-.28a2.47,2.47,0,0,0,.52.31l.09.68a.32.32,0,0,0,.32.27h1a.32.32,0,0,0,.32-.27l.09-.68a3.44,3.44,0,0,0,.53-.3L14,5.2a.34.34,0,0,0,.41-.14L15,4.2a.3.3,0,0,0-.09-.4Zm-2.38.94a1.25,1.25,0,0,1-1.28-1.23,1.29,1.29,0,0,1,2.57,0A1.26,1.26,0,0,1,11.91,4.32Z" />
    </g>
  </Svg>
);

export const Shop = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M15,5V4.89L13.91,2.35V1.11H1.07V2.35L0,4.89V5a2.64,2.64,0,0,0,0,.28A1.78,1.78,0,0,0,.94,6.82v7.07H14.06V6.82A1.78,1.78,0,0,0,15,5.25,2.64,2.64,0,0,0,15,5Zm-.71-.12H12L11.7,3h1.82ZM1.7,1.73H13.29v.62H1.7Zm9.74,3.74,0,.23-.18.24a1.16,1.16,0,0,1-.93.47,1.14,1.14,0,0,1-.93-.47l-.19-.25V5.47ZM9.2,4.85,9.11,3h2l.27,1.85Zm-.6.62h0V5.7l-.18.24a1.16,1.16,0,0,1-1.86,0l-.19-.25V5.47ZM6.42,4.85,6.52,3h2l.09,1.85Zm-2.76,0L3.93,3h2L5.8,4.85Zm2.11.62,0,.23-.18.24a1.14,1.14,0,0,1-.93.47,1.16,1.16,0,0,1-.93-.47l-.18-.23,0-.24ZM1.48,3H3.3L3,4.85H.73ZM.65,5.47H2.94l0,.25-.18.22a1.14,1.14,0,0,1-.92.47A1.15,1.15,0,0,1,.65,5.47Zm11.54,7.8H9.69V11.09H10a.32.32,0,0,0,0-.63H9.69V8.28h2.5Zm1.25,0h-.63V7.66H9.06v5.61H1.56V7h.23a1.77,1.77,0,0,0,1.42-.71A1.8,1.8,0,0,0,4.64,7a1.78,1.78,0,0,0,1.43-.71,1.8,1.8,0,0,0,2.86,0A1.78,1.78,0,0,0,10.36,7a1.8,1.8,0,0,0,1.43-.71A1.77,1.77,0,0,0,13.21,7h.23Zm-.23-6.86a1.14,1.14,0,0,1-.92-.47L12.1,5.7l0-.23h2.28A1.15,1.15,0,0,1,13.21,6.41Z" />
      <path d="M2.21,7.5v4.43H8.36V7.5Zm5.54,3.79H2.83V8.13H7.75Z" />
      <path d="M7.07,10a.29.29,0,0,0-.22.09l-.61.61a.31.31,0,0,0,.21.53.32.32,0,0,0,.22-.09l.62-.62A.3.3,0,0,0,7.07,10Z" />
      <path d="M7.07,8.48a.34.34,0,0,0-.22.09L5,10.42a.28.28,0,0,0-.09.22.3.3,0,0,0,.31.3.27.27,0,0,0,.21-.09L7.29,9a.34.34,0,0,0,.09-.22A.31.31,0,0,0,7.07,8.48Z" />
    </g>
  </Svg>
);

export const Megaphone = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M.48,4.4A.47.47,0,0,0,0,4.87V8.59a.48.48,0,1,0,1,0V4.87A.47.47,0,0,0,.48,4.4Z" />
      <path d="M10.57,1.62h-2a.48.48,0,0,0-.48.47v.43L1.73,4.42a.47.47,0,0,0-.34.45V8.59a.17.17,0,0,0,0,.07v0a.43.43,0,0,0,0,.05l1.36,4.32a.47.47,0,0,0,.45.33.34.34,0,0,0,.14,0l2-.61a.51.51,0,0,0,.28-.24.45.45,0,0,0,0-.36L5,10l3.14.93v.43a.48.48,0,0,0,.48.48h2a.47.47,0,0,0,.48-.48V2.09A.47.47,0,0,0,10.57,1.62ZM4.59,12l-1.06.33-.94-3,1.28.38Zm-.2-3.14h0l-2-.6v-3L8.11,3.52V10ZM10.1,10.9h-1V2.57h1Z" />
      <path d="M14.29,9.4l-2.06-1a.48.48,0,1,0-.43.85l2.07,1a.38.38,0,0,0,.21.05A.46.46,0,0,0,14.5,10,.47.47,0,0,0,14.29,9.4Z" />
      <path d="M12,5.15a.54.54,0,0,0,.21-.05l2.06-1a.48.48,0,0,0-.42-.86l-2.07,1a.48.48,0,0,0-.21.64A.47.47,0,0,0,12,5.15Z" />
      <path d="M14.52,6.26H12.8a.48.48,0,0,0,0,1h1.72a.48.48,0,1,0,0-1Z" />
    </g>
  </Svg>
);

export const Copy = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M10.6,2.81h-9A1.59,1.59,0,0,0,0,4.4v9A1.59,1.59,0,0,0,1.59,15h9a1.59,1.59,0,0,0,1.59-1.59v-9A1.59,1.59,0,0,0,10.6,2.81Zm.53,10.6a.53.53,0,0,1-.53.53h-9a.53.53,0,0,1-.53-.53v-9a.53.53,0,0,1,.53-.53h9a.53.53,0,0,1,.53.53Z" />
      <path d="M13.36,0H3.52A1.63,1.63,0,0,0,1.88,1.64a.55.55,0,0,0,.54.55A.55.55,0,0,0,3,1.64a.55.55,0,0,1,.55-.55h9.84a.55.55,0,0,1,.55.55v9.84a.55.55,0,0,1-.55.55.55.55,0,0,0-.55.55.55.55,0,0,0,.55.54A1.63,1.63,0,0,0,15,11.48V1.64A1.63,1.63,0,0,0,13.36,0Z" />
    </g>
  </Svg>
);

export const Marker = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path
        d="M7.5,0a6.55,6.55,0,1,1-.11,0Zm.34,1.35a5.26,5.26,0,1,0,4.91,4.91A5.26,5.26,0,0,0,7.84,1.35Z"
        style={{fillRule: 'evenodd'}}
      />
      <circle cx="7.5" cy="14.4" r="0.6" />
      <circle cx="7.5" cy="6.6" r="4.05" />
    </g>
  </Svg>
);

export const ColorWheel = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path
        d="M7.5,0A7.5,7.5,0,1,0,15,7.5,7.5,7.5,0,0,0,7.5,0ZM5.63,7.5A1.88,1.88,0,1,1,7.5,9.38,1.88,1.88,0,0,1,5.63,7.5Z"
        style={{fill: '#fdf49d'}}
      />
      <path d="M1.87,7.5H0A7.5,7.5,0,0,0,3.74,14l.94-1.63A5.62,5.62,0,0,1,1.87,7.5Z" style={{fill: '#f7d600'}} />
      <path
        d="M7.5,1.88a5.57,5.57,0,0,1,2.81.75L11.25,1A7.55,7.55,0,0,0,3.74,1l1,1.62A5.63,5.63,0,0,1,7.5,1.88Z"
        style={{fill: '#ff5daa'}}
      />
      <path d="M4.69,2.62,3.74,1A7.5,7.5,0,0,0,0,7.5H1.87A5.62,5.62,0,0,1,4.69,2.62Z" style={{fill: '#ff8911'}} />
      <path
        d="M7.5,13.12a5.6,5.6,0,0,1-2.82-.75L3.74,14a7.55,7.55,0,0,0,7.51,0l-.94-1.63a5.59,5.59,0,0,1-2.81.75Z"
        style={{fill: '#27e680'}}
      />
      <path d="M11.25,1l-.94,1.63A5.62,5.62,0,0,1,13.12,7.5H15A7.51,7.51,0,0,0,11.25,1Z" style={{fill: '#976dfd'}} />
      <path
        d="M10.31,12.37,11.25,14A7.51,7.51,0,0,0,15,7.5H13.12a5.62,5.62,0,0,1-2.81,4.87Z"
        style={{fill: '#6bdcff'}}
      />
      <path
        d="M5.63,4.25,4.69,2.62A5.62,5.62,0,0,0,1.87,7.5H3.75A3.72,3.72,0,0,1,5.63,4.25Z"
        style={{fill: '#ffa54b'}}
      />
      <path
        d="M7.5,11.25a3.72,3.72,0,0,1-1.88-.5l-.94,1.62a5.59,5.59,0,0,0,5.63,0l-.94-1.62a3.71,3.71,0,0,1-1.87.5Z"
        style={{fill: '#60f7a6'}}
      />
      <path d="M3.75,7.5H1.87a5.62,5.62,0,0,0,2.81,4.87l.94-1.62A3.75,3.75,0,0,1,3.75,7.5Z" style={{fill: '#ffeb17'}} />
      <path
        d="M9.37,10.75l.94,1.62A5.62,5.62,0,0,0,13.12,7.5H11.25a3.76,3.76,0,0,1-1.88,3.25Z"
        style={{fill: '#93e5ff'}}
      />
      <path
        d="M4.69,2.62l.94,1.63a3.71,3.71,0,0,1,1.87-.5,3.65,3.65,0,0,1,1.88.51l.93-1.63A5.57,5.57,0,0,0,7.5,1.88,5.63,5.63,0,0,0,4.69,2.62Z"
        style={{fill: '#ff77b7'}}
      />
      <path
        d="M11.25,7.5h1.87a5.62,5.62,0,0,0-2.81-4.87L9.38,4.26A3.73,3.73,0,0,1,11.25,7.5Z"
        style={{fill: '#ac8bfd'}}
      />
      <path
        d="M7.5,9.38a1.9,1.9,0,0,1-1-.26l-.93,1.63a3.77,3.77,0,0,0,3.75,0L8.43,9.12a1.83,1.83,0,0,1-.93.26Z"
        style={{fill: '#a0f7c9'}}
      />
      <path
        d="M8.43,9.12l.94,1.63A3.76,3.76,0,0,0,11.25,7.5H9.37A1.87,1.87,0,0,1,8.43,9.12Z"
        style={{fill: '#bff0ff'}}
      />
      <path d="M3.75,7.5H5.62a1.86,1.86,0,0,1,1-1.63L5.63,4.25A3.72,3.72,0,0,0,3.75,7.5Z" style={{fill: '#ffbd7a'}} />
      <path
        d="M9.37,7.5h1.88A3.73,3.73,0,0,0,9.38,4.26L8.45,5.88A1.89,1.89,0,0,1,9.37,7.5Z"
        style={{fill: '#bda2fd'}}
      />
      <path
        d="M7.5,5.62a1.93,1.93,0,0,1,.95.26l.93-1.62A3.65,3.65,0,0,0,7.5,3.75a3.71,3.71,0,0,0-1.87.5l.94,1.62A1.82,1.82,0,0,1,7.5,5.62Z"
        style={{fill: '#ffa6dd'}}
      />
    </g>
  </Svg>
);

export const Bike = ({className, children, hasFlag}: PropsWithChildren<SvgIconProps> & {hasFlag?: boolean}) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <g transform={hasFlag ? 'translate(-0.6818 1.8915)' : ''}>
        <path d="M8.9,3.1a1,1,0,0,1,.9.6L9.92,4h0l0,.05,0,.07,1,2.29.2.47a2.54,2.54,0,1,1-.74.31c-.06-.14-.12-.29-.19-.44L8.53,8.93a1.52,1.52,0,0,1-.13,1.34l.53.55a.4.4,0,1,1-.57.56l-.56-.57a1.51,1.51,0,0,1-.68.16,16.91,16.91,0,0,1-1.75-.21,2.56,2.56,0,1,1-1.48-3.9l.57-1.11L4.2,5.23H3.35a.4.4,0,1,1,0-.8h2a.4.4,0,0,1,0,.8H5.1L6.55,8a1,1,0,0,1,.27,0,1.76,1.76,0,0,1,.92.11c.26.15.2.1.28.17L9.85,5.88c-.09-.22-.06-.14-.14-.35h0l-.36-.85h0c-.15-.37-.09-.23-.17-.41h0L9.06,4A.19.19,0,0,0,8.9,3.9H8.26V3.11H8.9ZM3.12,7.59A1.84,1.84,0,0,0,1.48,9.34a1.76,1.76,0,0,0,3,1.28l-1.67-.25a.91.91,0,0,1,0-1.8l.26,0,.48-.93Zm8.84,0a2.81,2.81,0,0,0-.5,0l.67,1.57a.42.42,0,0,1-.21.53.4.4,0,0,1-.53-.21l-.67-1.58a1.77,1.77,0,1,0,1.86-.13A1.44,1.44,0,0,0,12,7.57ZM7.12,8.78c-1.42.16-2.85.34-4.26.59a.11.11,0,0,0,0,.21c.28,0,4,.61,4.25.59,0,0-.35-.39-.35-.39a.4.4,0,1,1,.57-.56l.44.44a.7.7,0,0,0-.58-.88ZM4.26,7.91,4,8.38l.64-.09a1.56,1.56,0,0,0-.39-.38Zm.66-1.29-.29.57a2.56,2.56,0,0,1,.88,1l.19,0L4.92,6.62Z" />
      </g>
      {hasFlag && (
        <path d="M14.85,1.68H8.65V1.37a.16.16,0,0,0-.15-.16H8a.16.16,0,0,0-.15.16v9.5A.16.16,0,0,0,8,11H8.5a.16.16,0,0,0,.15-.16V7.07h6.2A.16.16,0,0,0,15,6.91V1.84a.16.16,0,0,0-.15-.16Zm-6.51,9H8.19V1.53h.15ZM14.7,3.59H13.18V5.17H14.7V6.75H13.18V5.17H11.67V6.75H10.16V5.17H8.65V3.59h1.51V2h1.51V3.59h1.51V2H14.7V3.59Zm-4.54,0h1.51V5.18H10.16V3.59Z" />
      )}
    </g>
  </Svg>
);

export const Car = ({className, children, hasFlag}: PropsWithChildren<SvgIconProps> & {hasFlag?: boolean}) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <g transform={hasFlag ? 'translate(-0.4044 2.9326)' : ''}>
        <path d="M11,6,11,6a6.16,6.16,0,0,0,1.42.4c1.41.31,1.67.8,1.72,1A.91.91,0,0,1,14.5,8a.82.82,0,0,0,.07.29l0,0V9.78c-.07.39-.44.59-1.05.59a2.4,2.4,0,0,1-.33,0h-.1v0a1.59,1.59,0,0,0,.12-.62,1.69,1.69,0,1,0-3.37,0,1.51,1.51,0,0,0,.07.48l0,.1H9.83L4.4,10.14a1.61,1.61,0,0,0,.06-.44,1.69,1.69,0,0,0-3.38,0,2.28,2.28,0,0,0,0,.26,1,1,0,0,1-.55-.53A2.12,2.12,0,0,1,.52,8a3,3,0,0,0,.1-.73c0-.26,0-.56.08-.82S.8,6,1,6h.11l0,0a2.62,2.62,0,0,1,.7-.78L2.1,5a.5.5,0,0,1,.06-.38.28.28,0,0,1,.23-.11h.08A2.58,2.58,0,0,0,3,4.39,16.42,16.42,0,0,1,6.44,4,7,7,0,0,1,11,6Z" />
        <path d="M4.12,9.7A1.35,1.35,0,1,1,2.77,8.34,1.35,1.35,0,0,1,4.12,9.7Z" />
        <path d="M12.91,9.7a1.36,1.36,0,1,1-1.36-1.36A1.36,1.36,0,0,1,12.91,9.7Z" />
      </g>
      {hasFlag && (
        <path d="M14.85,1.48h-6V1.17A.15.15,0,0,0,8.66,1H8.22a.15.15,0,0,0-.14.15v9.26a.15.15,0,0,0,.14.15h.44a.15.15,0,0,0,.15-.15V6.73h6A.16.16,0,0,0,15,6.57V1.64a.16.16,0,0,0-.15-.16ZM8.52,10.27H8.37V1.33h.15Zm6.19-6.94H13.23V4.88h1.48V6.42H13.23V4.88H11.76V6.42H10.29V4.88H8.81V3.33h1.48V1.79h1.47V3.33h1.47V1.79h1.48V3.33Zm-4.42,0h1.47V4.88H10.29V3.33Z" />
      )}
    </g>
  </Svg>
);

export const Biro = ({className, children, hasFlag}: PropsWithChildren<SvgIconProps> & {hasFlag?: boolean}) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <g transform={hasFlag ? 'translate(-0.3841 0.8747)' : ''}>
        <path d="M4.21,12.09a1.9,1.9,0,1,1-1.9-1.89A1.9,1.9,0,0,1,4.21,12.09Z" />
        <path d="M14.62,12.09a1.9,1.9,0,1,1-1.9-1.89A1.9,1.9,0,0,1,14.62,12.09Z" />
        <path d="M4.53,1l2.41.22a5.82,5.82,0,0,1,4.82,5.82v.11a2.93,2.93,0,0,1,2.54,2l0,.13.05.51.08.7a2.36,2.36,0,0,0-4.12,1.58,2.39,2.39,0,0,0,.09.66H4.59a2.38,2.38,0,0,0-2.28-3,2.39,2.39,0,0,0-1.93,1c0-1.09.15-1.32.18-1.35h0a2.8,2.8,0,0,1,.34-.2c0-.63.2-1,.59-1.14,0-.27.07-.56.1-.89a3.06,3.06,0,0,1-.09-.65V4.28a3.87,3.87,0,0,1,1-2.61,2.66,2.66,0,0,1,2-.64Zm-.17.37a1.94,1.94,0,0,0-1.67.69,4.08,4.08,0,0,0-.77,2q0,1,0,2.37a3.46,3.46,0,0,0,.85,2,3.72,3.72,0,0,0,.75.49c1-.21.79-.4,1.84-.4H6.48l.46.12a.79.79,0,0,0,.35.56c0,1-.07,1.51-.08,1.62a6.84,6.84,0,0,0,3.62-1.5,2.8,2.8,0,0,1,.65-.4,10,10,0,0,0-.72-4.13c-.69-1.42-2-2.94-3.35-3.09-.91-.1-1.93-.2-3-.28Z" />
      </g>
      {hasFlag && (
        <path d="M14.45.72H6.91V.34a.19.19,0,0,0-.18-.2H6.18A.19.19,0,0,0,6,.34V11.88a.18.18,0,0,0,.18.19h.55a.18.18,0,0,0,.18-.19V7.26h7.54a.18.18,0,0,0,.18-.19V.91a.19.19,0,0,0-.18-.19Zm-7.9,11H6.36V.53h.19ZM14.26,3H12.43V5h1.83V6.88H12.43V5H10.59V6.88H8.75V5H6.91V3H8.75V1.11h1.84V3h1.84V1.11h1.83V3ZM8.75,3h1.84V5H8.75V3Z" />
      )}
    </g>
  </Svg>
);

export const Kick = ({className, children, hasFlag}: PropsWithChildren<SvgIconProps> & {hasFlag?: boolean}) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <g transform={hasFlag ? 'translate(-1.1822 1.5141)' : ''}>
        <path d="M5.77,9.67h4.54a1,1,0,0,1,1,1.08h0a1,1,0,0,1-1,1.08H5.77a1,1,0,0,1-1-1.08h0a1,1,0,0,1,1-1.08Z" />
        <path d="M2.81,8.94a1.63,1.63,0,1,1-1.63,1.63A1.63,1.63,0,0,1,2.81,8.94Zm0,.89a.74.74,0,1,0,.74.74A.74.74,0,0,0,2.81,9.83Z" />
        <path d="M12.19,8.94a1.63,1.63,0,1,1-1.62,1.63,1.63,1.63,0,0,1,1.62-1.63Zm0,.89a.74.74,0,1,0,.74.74A.74.74,0,0,0,12.19,9.83Z" />
        <path d="M9,2.81h1.89s.34,0,.46.51l1,4.51a.61.61,0,0,1-.49.73h0a.63.63,0,0,1-.75-.47l-1-4.71H9a.29.29,0,0,1-.23-.29A.29.29,0,0,1,9,2.81Z" />
      </g>
      {hasFlag && (
        <path d="M14.85,1.78H8.51V1.45a.16.16,0,0,0-.15-.16H7.89a.16.16,0,0,0-.15.16v9.71a.16.16,0,0,0,.15.16h.47a.16.16,0,0,0,.15-.16V7.28h6.34A.16.16,0,0,0,15,7.12V1.94a.16.16,0,0,0-.15-.16ZM8.2,11H8.05V1.62H8.2Zm6.49-7.28H13.15V5.34h1.54V7H13.15V5.34H11.6V7H10.06V5.34H8.51V3.72h1.55V2.1H11.6V3.72h1.55V2.1h1.54V3.72Zm-4.63,0H11.6V5.34H10.06V3.72Z" />
      )}
    </g>
  </Svg>
);

export const Scooter = ({className, children, hasFlag}: PropsWithChildren<SvgIconProps> & {hasFlag?: boolean}) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <g transform={hasFlag ? 'translate(-0.6818 1.7898)' : ''}>
        <path d="M6.65,9.52H8.52L9.6,6.67,8.4,3.48l-1,0s-.3,0-.3-.3.3-.25.3-.25H9.14l2.44,3.9v.4L9.25,10.74h-4V9.67a2.61,2.61,0,0,0-2.35-2,2.36,2.36,0,0,0-1.41.45H1.35L1,7.6V7.41L2.26,5.5h.37l3,.55a11.57,11.57,0,0,1,1,3.47Z" />
        <path d="M2.56,8.35A1.88,1.88,0,1,1,.68,10.23,1.88,1.88,0,0,1,2.56,8.35Zm0,.94a.94.94,0,1,0,.93.94A.94.94,0,0,0,2.56,9.29Z" />
        <path d="M12.44,8.35a1.88,1.88,0,1,1-1.87,1.88A1.88,1.88,0,0,1,12.44,8.35Zm0,.94a.94.94,0,1,0,.94.94A.94.94,0,0,0,12.44,9.29Z" />
      </g>
      {hasFlag && (
        <path d="M14.85,1.58H8.65V1.27a.16.16,0,0,0-.15-.16H8a.16.16,0,0,0-.15.16v9.5a.16.16,0,0,0,.15.16H8.5a.16.16,0,0,0,.15-.16V7h6.2A.16.16,0,0,0,15,6.81V1.74a.16.16,0,0,0-.15-.16Zm-6.51,9H8.19V1.42h.15ZM14.7,3.48H13.18V5.07H14.7V6.65H13.18V5.07H11.67V6.65H10.16V5.07H8.65V3.48h1.51V1.9h1.51V3.48h1.51V1.9H14.7V3.48Zm-4.54,0h1.51V5.07H10.16V3.48Z" />
      )}
    </g>
  </Svg>
);

export const PolygonIntersection = ({
  className,
  children,
  isInverted,
}: PropsWithChildren<SvgIconProps> & {isInverted?: boolean}) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <rect
        height="8.4"
        rx="1.87"
        style={{fill: isInverted ? '#0ac2bf' : '#ffffff', stroke: '#D9D9D9'}}
        width="8.4"
        x="0.97"
        y="6.1"
      />
      <rect
        height="8.4"
        rx="1.87"
        style={{fill: isInverted ? '#0ac2bf' : '#ffffff', stroke: '#D9D9D9'}}
        width="8.4"
        x="5.63"
        y="0.5"
      />
      <path
        d="M8,5.6A1.89,1.89,0,0,1,9.86,7.5V9.41H7A1.9,1.9,0,0,1,5.14,7.5V5.6Z"
        style={{fill: isInverted ? '#ffffff' : '#0ac2bf', fillRule: 'evenodd'}}
      />
    </g>
  </Svg>
);

export const PolygonDifference = ({
  className,
  children,
  isInverted,
}: PropsWithChildren<SvgIconProps> & {isInverted?: boolean}) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <rect height="9.35" rx="1.87" style={{fill: isInverted ? '#ffffff' : '#0ac2bf'}} width="9.35" x="0.47" y="5.65" />
      <rect
        height="8.42"
        rx="1.87"
        style={{fill: isInverted ? '#0ac2bf' : '#ffffff', stroke: '#D9D9D9'}}
        width="8.42"
        x="5.61"
        y="0.5"
      />
    </g>
  </Svg>
);

export const PolygonUnion = ({
  className,
  children,
  isInverted,
}: PropsWithChildren<SvgIconProps> & {isInverted?: boolean}) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path
      d="M12.66,0a1.88,1.88,0,0,1,1.87,1.88V7.5a1.88,1.88,0,0,1-1.87,1.88H9.84v3.74A1.88,1.88,0,0,1,8,15H2.34A1.88,1.88,0,0,1,.47,13.12V7.5A1.88,1.88,0,0,1,2.34,5.62H5.16V1.88A1.88,1.88,0,0,1,7,0Z"
      style={{fill: isInverted ? '#ffffff' : '#0ac2bf', fillRule: 'evenodd'}}
    />
  </Svg>
);

export const Pin = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M11.25,3.81a3.75,3.75,0,1,0-7.5,0,3.79,3.79,0,0,0,3.5,3.8v7.14a.25.25,0,0,0,.5,0V7.61A3.79,3.79,0,0,0,11.25,3.81Zm-5,0a1,1,0,1,1,1-1A1,1,0,0,1,6.25,3.81Z" />
  </Svg>
);

export const Circle = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <circle cx="7.5" cy="7.5" r="7.5" />
  </Svg>
);

export const CircleIndicator = () => styled.div``;

CircleIndicator.Default = styled(Circle)<{fill?: string}>`
  width: 1.375rem;
  height: 1.375rem;

  circle  {
    fill: ${({fill}) => fill};
  }
`;

CircleIndicator.Boolean = styled(CircleIndicator.Default).attrs((props: any) => {
  return {fill: props.active ? '#0AA077' : '#CE0000'};
})<{active: boolean}>``;

export const Filter = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M5.49,7.4c.23.34.16.06.16,6.86a.74.74,0,0,0,1.19.59c2.09-1.57,2.5-1.71,2.5-2.45,0-4.95-.05-4.67.17-5L12.9,2.78H2.09Z" />
      <path d="M14.19.38A.7.7,0,0,0,13.57,0H1.43A.7.7,0,0,0,.86,1.1s0-.05.59.8h12.1C14.08,1.18,14.44.86,14.19.38Z" />
    </g>
  </Svg>
);

export const Avatar = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M13.33,10.24a10.75,10.75,0,0,0-11.66,0,1.84,1.84,0,0,0-.82,1.54V15h13.3V11.78a1.84,1.84,0,0,0-.82-1.54Z" />
    <path d="M10.15,1.1a3.75,3.75,0,1,1-5.3,0,3.74,3.74,0,0,1,5.3,0" />
  </Svg>
);

export const Ellipsis = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <circle cx="1.97" cy="7.5" r="1.97" />
      <circle cx="7.5" cy="7.5" r="1.97" />
      <circle cx="13.03" cy="7.5" r="1.97" />
    </g>
  </Svg>
);

export const ManualPayment = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M6.65,11.84A30.46,30.46,0,0,0,9,9.43h5.06A.92.92,0,0,0,15,8.51V3.72a.93.93,0,0,0-.93-.93H5.21a.93.93,0,0,0-.93.93V5.8L5,5.72a.46.46,0,0,1,.07-.07V4.73a.85.85,0,0,0,.39,0,.95.95,0,0,0,.75-1.16h6.9a1.11,1.11,0,0,0,0,.17,1,1,0,0,0,.94.94h.17V7.54l-.17,0a.94.94,0,0,0-.94.94.88.88,0,0,0,0,.17H4.45a3.58,3.58,0,0,1,1-.68s2-.18,2-1.19V6.68s-.06-.76-2.24-.13l-2.42.28A5.55,5.55,0,0,0,.53,8H.26A.26.26,0,0,0,0,8.28v2.84a.27.27,0,0,0,.23.26,44.59,44.59,0,0,1,5,.72.92.92,0,0,0,.27.08A1.21,1.21,0,0,0,6.65,11.84Z" />
      <circle cx="12.44" cy="6.16" r="0.58" />
      <path d="M9.57,4.07a2.06,2.06,0,0,0-2,1.59,1.19,1.19,0,0,1,.15.09,1.17,1.17,0,0,1,.53.84,1,1,0,0,1,0,.17,1.68,1.68,0,0,1-.18.74,2.07,2.07,0,0,0,1.5.64,2,2,0,1,0,0-4.07Zm.18.81c0,.13,0,.13.12.15a.9.9,0,0,1,.28.08s.07,0,0,.1l-.06.23c0,.07-.05.08-.12,0a.83.83,0,0,0-.44-.09.2.2,0,0,0-.11,0,.16.16,0,0,0,0,.29.85.85,0,0,0,.19.1L10,6a.62.62,0,0,1,.2.94.68.68,0,0,1-.34.21c-.06,0-.09,0-.09.11a1.08,1.08,0,0,1,0,.18c0,.06,0,.08-.08.08H9.46a.08.08,0,0,1-.09-.09V7.27c0-.1,0-.1-.1-.11a1.54,1.54,0,0,1-.35-.1c-.08,0-.09-.07-.07-.15l.06-.2c0-.08.05-.09.12,0a1,1,0,0,0,.39.11.37.37,0,0,0,.25,0,.19.19,0,0,0,.05-.34l-.15-.09a3.42,3.42,0,0,1-.4-.17.54.54,0,0,1-.31-.53.58.58,0,0,1,.43-.54c.1,0,.1,0,.11-.15V4.81c0-.08,0-.1.1-.1h.07C9.75,4.71,9.75,4.71,9.75,4.88Z" />
    </g>
  </Svg>
);

export const CreditCard = {
  Unknown: ({className, children}: PropsWithChildren<SvgIconProps>) => (
    <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
      {children}
      <defs>
        <clipPath id="a">
          <rect height="9.64" rx="0.8" style={{fill: 'none'}} width="15" y="2.68" />
        </clipPath>
        <clipPath id="b">
          <rect height="2.68" style={{fill: 'none'}} width="15" y="4.02" />
        </clipPath>
      </defs>
      <g style={{clipPath: 'url(#a)'}}>
        <g>
          <path
            d="M15,11.52a.8.8,0,0,1-.8.8H.8a.8.8,0,0,1-.8-.8v-8a.8.8,0,0,1,.8-.8H14.2a.8.8,0,0,1,.8.8Z"
            style={{fill: '#75a2d9'}}
          />
          <rect
            height="1.07"
            style={{fill: '#fff', opacity: 0.6, isolation: 'isolate'}}
            width="9.64"
            x="2.68"
            y="7.77"
          />
          <rect
            height="1.07"
            style={{fill: '#fff', opacity: 0.6, isolation: 'isolate'}}
            width="6.96"
            x="4.02"
            y="9.64"
          />
          <polygon points="0 12.32 14.38 12.32 0 4.02 0 12.32" style={{opacity: '0.2', isolation: 'isolate'}} />
          <g style={{clipPath: 'url(#b)'}}>
            <g>
              <rect height="2.68" width="15" y="4.02" />
              <polygon
                points="13.45 6.7 4.64 6.7 6.18 4.02 15 4.02 13.45 6.7"
                style={{fill: '#fff', opacity: 0.1, isolation: 'isolate'}}
              />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  ),
  MasterCard: ({className, children}: PropsWithChildren<SvgIconProps>) => (
    <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
      {children}
      <defs>
        <clipPath id="a">
          <path
            d="M15,11.52a.8.8,0,0,1-.81.8H.77a.76.76,0,0,1-.77-.8v-8a.76.76,0,0,1,.77-.8H14.19a.8.8,0,0,1,.81.8Z"
            style={{fill: 'none'}}
          />
        </clipPath>
      </defs>
      <g style={{clipPath: 'url(#a)'}}>
        <g>
          <path
            d="M15,11.52a.8.8,0,0,1-.81.8H.77a.76.76,0,0,1-.77-.8v-8a.76.76,0,0,1,.77-.8H14.19a.8.8,0,0,1,.81.8Z"
            style={{fill: '#616276'}}
          />
          <polygon
            points="9.71 2.68 0 2.68 0 12.32 4.15 12.32 9.71 2.68"
            style={{fill: '#fff', opacity: 0.15, isolation: 'isolate'}}
          />
          <polygon
            points="10.61 12.32 -1.18 12.32 2.61 5.76 12.39 9.24 10.61 12.32"
            style={{fill: '#231f20', opacity: 0.3, isolation: 'isolate'}}
          />
          <circle cx="5.63" cy="7.5" r="3.48" style={{fill: '#c8441d'}} />
          <circle cx="9.38" cy="7.5" r="3.48" style={{fill: '#eabe53'}} />
          <circle cx="5.63" cy="7.5" r="3.48" style={{fill: '#c7441c', opacity: 0.5, isolation: 'isolate'}} />
        </g>
      </g>
    </Svg>
  ),
  Visa: ({className, children}: PropsWithChildren<SvgIconProps>) => (
    <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
      {children}
      <defs>
        <clipPath id="a">
          <rect height="9.64" rx="0.8" style={{fill: 'none'}} width="15" y="2.68" />
        </clipPath>
      </defs>
      <g style={{clipPath: 'url(#a)'}}>
        <g>
          <path
            d="M15,11.52a.79.79,0,0,1-.8.8H.8a.79.79,0,0,1-.8-.8v-8a.79.79,0,0,1,.8-.8H14.2a.79.79,0,0,1,.8.8Z"
            style={{fill: '#dedcd9'}}
          />
          <polygon
            points="3.61 12.32 0 12.32 0 2.68 9.18 2.68 3.61 12.32"
            style={{fill: '#fff', opacity: 0.3, isolation: 'isolate'}}
          />
          <polygon
            points="15 12.32 6.45 12.32 5.83 11.25 11.75 3.75 15 9.39 15 12.32"
            style={{fill: '#231f20', opacity: 0.15, isolation: 'isolate'}}
          />
          <path
            d="M6.58,4.27s.76,4.61.75,4.61,2.05-5.12,2-5.13h2.38s-3.34,7.5-3.36,7.5H5.83L4.25,3.75Z"
            style={{fill: '#5e749b'}}
          />
          <path
            d="M5.5,5A3.71,3.71,0,0,0,3.22,4s0-.26,0-.27H6.06c.26,0,.43,0,.52.52S7,7,7,7A7.35,7.35,0,0,0,5.5,5Z"
            style={{fill: '#eabe53'}}
          />
        </g>
      </g>
    </Svg>
  ),
  AmericanExpress: ({className, children}: PropsWithChildren<SvgIconProps>) => (
    <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
      {children}
      <defs>
        <clipPath id="a">
          <rect height="9.91" rx="0.8" style={{fill: 'none'}} width="15" y="2.54" />
        </clipPath>
      </defs>
      <g style={{clipPath: 'url(#a)'}}>
        <g>
          <path
            d="M15,11.65a.79.79,0,0,1-.8.81H.8a.79.79,0,0,1-.8-.81V3.35a.79.79,0,0,1,.8-.81H14.2a.79.79,0,0,1,.8.81Z"
            style={{fill: '#75a2d8'}}
          />
          <polygon
            points="15 12.46 15 2.54 9.2 2.54 3.48 12.46 15 12.46"
            style={{fill: '#231f20', opacity: 0.3, isolation: 'isolate'}}
          />
          <polygon
            points="13 7.1 14.7 5.22 12.43 5.22 11.86 5.85 11.29 5.22 6.61 5.22 6.16 6.26 5.68 5.22 3.75 5.22 3.75 5.77 3.52 5.22 1.86 5.22 0.26 8.97 2.12 8.97 2.34 8.44 3.01 8.44 3.23 8.97 5.46 8.97 5.46 8.85 5.52 8.97 6.78 8.97 6.84 8.85 6.84 8.97 11.29 8.97 11.86 8.35 12.43 8.97 14.7 8.97 13 7.1"
            style={{fill: '#dedcd9'}}
          />
          <g>
            <path
              d="M6.18,7.58,5.34,5.76h-1V8.44L3.16,5.76h-1L1.07,8.44h.69L2,7.9H3.37l.22.54H4.93V6.34l.94,2.1h.56l.94-2.1v2.1H8V5.76H7ZM2.67,6.21l.48,1.16H2.2ZM13.49,8.44,12.27,7.1l1.22-1.34h-.83l-.8.88-.8-.88H8.57V8.44h2.49l.8-.89.8.89ZM10.72,7.9H9.25V7.37h1.46V6.83H9.25V6.29h1.47l.73.81Z"
              style={{fill: '#75a2d8'}}
            />
            <g style={{opacity: 0.3}}>
              <path
                d="M6.18,7.58,5.34,5.76h-1V8.44L3.16,5.76h-1L1.07,8.44h.69L2,7.9H3.37l.22.54H4.93V6.34l.94,2.1h.56l.94-2.1v2.1H8V5.76H7ZM2.67,6.21l.48,1.16H2.2ZM13.49,8.44,12.27,7.1l1.22-1.34h-.83l-.8.88-.8-.88H8.57V8.44h2.49l.8-.89.8.89ZM10.72,7.9H9.25V7.37h1.46V6.83H9.25V6.29h1.47l.73.81Z"
                style={{fill: '#231f20'}}
              />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  ),
};

export const Geofence = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <g>
        <polyline
          id="fill"
          points="6.04 1.4 4.57 3.1 0.67 3.69 1.89 7.58 0.67 11.53 5.75 13.77 6.63 11.53 10.92 13.77 11.84 11.02 10.12 8.88 14.48 5.06 11.84 2.23 8.87 2.23"
          style={{fillRule: 'evenodd', opacity: 0.4}}
        />
        <polyline
          id="stroke"
          points="6.04 1.4 4.57 3.1 0.67 3.69 1.89 7.58 0.67 11.53 5.75 13.77 6.63 11.53 10.92 13.77 11.84 11.02 10.12 8.88 14.48 5.06 11.84 2.23 8.87 2.23"
          style={{
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '0.6347826086956305px',
          }}
        />
      </g>
      <g>
        <circle cx="10.2" cy="8.77" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="14.32" cy="4.96" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="11.78" cy="10.99" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="10.83" cy="13.53" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="6.71" cy="11.63" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="5.75" cy="13.85" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="0.68" cy="11.63" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="1.95" cy="7.5" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="0.68" cy="3.69" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="4.48" cy="3.06" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="6.07" cy="1.15" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
        <circle cx="8.61" cy="2.23" r="0.48" style={{strokeWidth: '0.31739130434781526px'}} />
      </g>
    </g>
  </Svg>
);

Geofence.Default = styled(Geofence)<{isActive: boolean}>`
  g {
    polyline {
      &#fill {
        fill: ${({isActive}) => (isActive ? '#3e88f1' : '#ffffff')};
      }

      &#stroke {
        stroke: ${({isActive}) => (isActive ? '#3e88f1' : '#ffffff')};
      }
    }

    circle {
      fill: #c1d4ee;
      stroke: #e6e6e6;
    }
  }
`;

export const Ticket = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M12,11.25H3V9.75h9Zm0-3H3V6.75h9Zm0-3H3V3.75h9ZM.75,15l1.13-1.12L3,15l1.12-1.12L5.25,15l1.13-1.12L7.5,15l1.12-1.12L9.75,15l1.13-1.12L12,15l1.12-1.12L14.25,15V0L13.12,1.12,12,0,10.88,1.12,9.75,0,8.62,1.12,7.5,0,6.38,1.12,5.25,0,4.12,1.12,3,0,1.88,1.12.75,0Z" />
  </Svg>
);

export const Gift = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <path d="M13.65,2.58h-3.4A1.64,1.64,0,0,0,7.75.48,1.21,1.21,0,0,0,7.5.83,1.21,1.21,0,0,0,7.25.48a1.64,1.64,0,0,0-2.5,2.1H1.35A1.34,1.34,0,0,0,0,3.93V5A1.34,1.34,0,0,0,.94,6.26v7.39A1.34,1.34,0,0,0,2.29,15H12.71a1.34,1.34,0,0,0,1.35-1.35V6.26A1.34,1.34,0,0,0,15,5V3.93A1.34,1.34,0,0,0,13.65,2.58ZM8,5.39H7V3.52H8Zm.11-3.21c.14-.83.31-1,.33-1a.74.74,0,0,1,.5-.2.7.7,0,0,1,.49,1.2s-.2.18-1,.33L8,2.52C8,2.41,8.06,2.3,8.08,2.18Zm-2.48-1a.7.7,0,0,1,.49-.2.74.74,0,0,1,.5.2s.19.21.33,1c0,.12,0,.24,0,.35l-.34,0c-.83-.15-1-.31-1-.33a.7.7,0,0,1,0-1ZM.94,3.93a.41.41,0,0,1,.41-.41H6.09V5.39H1.35A.41.41,0,0,1,.94,5Zm.94,9.72V6.33H6.09v7.73H2.29A.41.41,0,0,1,1.88,13.65ZM7,14.06V6.33H8v7.73Zm6.1-.41a.41.41,0,0,1-.42.41H8.91V6.33h4.22ZM14.06,5a.41.41,0,0,1-.41.41H8.91V3.52h4.74a.41.41,0,0,1,.41.41Z" />
  </Svg>
);

export const Coupon = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M13.59,13.13H1.41A1.41,1.41,0,0,1,0,11.72V9.37a.47.47,0,0,1,.47-.46,1.41,1.41,0,0,0,0-2.82A.47.47,0,0,1,0,5.63V3.28A1.41,1.41,0,0,1,1.41,1.87H13.59A1.41,1.41,0,0,1,15,3.28V5.63a.47.47,0,0,1-.47.46,1.41,1.41,0,0,0,0,2.82.47.47,0,0,1,.47.47v2.34a1.41,1.41,0,0,1-1.41,1.41ZM.94,9.8v1.92a.47.47,0,0,0,.47.47H13.59a.47.47,0,0,0,.47-.47V9.8a2.35,2.35,0,0,1,0-4.6V3.28a.47.47,0,0,0-.47-.47H1.41a.47.47,0,0,0-.47.47V5.2a2.35,2.35,0,0,1,0,4.6Z" />
      <polygon points="5.66 11.08 8.47 3.58 9.34 3.91 6.53 11.41 5.66 11.08" />
      <path d="M5.28,7.66A1.41,1.41,0,1,1,6.69,6.25,1.41,1.41,0,0,1,5.28,7.66Zm0-1.88a.47.47,0,1,0,.47.47A.47.47,0,0,0,5.28,5.78Z" />
      <path d="M10.13,10.56a1.41,1.41,0,1,1,1.4-1.4,1.41,1.41,0,0,1-1.4,1.4Zm0-1.87a.47.47,0,1,0,.47.47A.47.47,0,0,0,10.13,8.69Z" />
    </g>
  </Svg>
);

export const Finish = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path
        d="M2.13,13.26A.27.27,0,0,1,1.87,13c-.09-.6-.4-2.65-.73-4.91L.68,5.08A.27.27,0,0,1,.9,4.79.28.28,0,0,1,1.2,5l.45,3.05c.34,2.26.64,4.31.73,4.91a.25.25,0,0,1,0,.19.28.28,0,0,1-.17.1Z"
        style={{fill: '#b19046'}}
      />
      <path
        d="M12.87,13.26h0a.28.28,0,0,1-.17-.1.25.25,0,0,1,0-.19c.09-.59.38-2.57.71-4.79L13.8,5a.28.28,0,0,1,.3-.21.27.27,0,0,1,.22.29l-.47,3.17c-.33,2.21-.63,4.2-.72,4.79A.27.27,0,0,1,12.87,13.26Z"
        style={{fill: '#b19046'}}
      />
      <path
        d="M15,.93c0,.12,0,.28-.08.49l-.57,3.79a.31.31,0,0,1-.34.26,45.08,45.08,0,0,0-13,0,.31.31,0,0,1-.34-.26C.46,3.94.26,2.67.07,1.4a.28.28,0,0,0,0-.09.77.77,0,0,1,0-.15.2.2,0,0,1,0-.07H0Q0,1,0,.93A.31.31,0,0,1,.26.58a50.18,50.18,0,0,1,14.48,0A.31.31,0,0,1,15,.93Z"
        style={{fill: '#cc4b4c'}}
      />
      <path
        d="M2.16,4.11a.26.26,0,0,1-.24-.23L1.71,1.76a.54.54,0,0,1,.11-.41.57.57,0,0,1,.37-.22l.62-.06A.23.23,0,0,1,3,1.18a.31.31,0,0,1,0,.25.24.24,0,0,1-.2.15l-.61.06-.05,0a0,0,0,0,0,0,0l.21,2.13a.24.24,0,0,1-.21.28Z"
        style={{fill: '#ecf0f1'}}
      />
      <path
        d="M2.39,3.1c-.12,0-.21-.13-.22-.29a.27.27,0,0,1,.2-.31l.57-.08a.22.22,0,0,1,.21.13.39.39,0,0,1,0,.3A.22.22,0,0,1,3,3l-.56.08Z"
        style={{fill: '#ecf0f1'}}
      />
      <path
        d="M4.17,4.11a.25.25,0,0,1-.25-.24L3.75,1.35A.26.26,0,0,1,4,1.07a.26.26,0,0,1,.27.24l.17,2.52A.26.26,0,0,1,4.37,4a.23.23,0,0,1-.18.09Z"
        style={{fill: '#ecf0f1'}}
      />
      <path
        d="M7.82,4.11h0A.36.36,0,0,1,7.59,4a.23.23,0,0,1-.09-.18l0-2.52a.3.3,0,0,1,.33-.26.35.35,0,0,1,.22.08.25.25,0,0,1,.09.19l0,2.51A.29.29,0,0,1,7.82,4.11Z"
        style={{fill: '#ecf0f1'}}
      />
      <path
        d="M5.5,4.11a.27.27,0,0,1-.28-.25L5.11,1.38a.29.29,0,0,1,.2-.27.29.29,0,0,1,.32.12c.33.51.65,1,1,1.54l0-1.44a.25.25,0,0,1,.08-.18.28.28,0,0,1,.2-.08h0a.26.26,0,0,1,.28.25l0,2.49A.28.28,0,0,1,7,4.07a.28.28,0,0,1-.32-.13c-.29-.52-.6-1-.91-1.54l.06,1.44a.26.26,0,0,1-.27.27Z"
        style={{fill: '#ecf0f1'}}
      />
      <g>
        <path
          d="M9.72,2.92h0a.87.87,0,0,1-.61-.3A.94.94,0,0,1,8.86,2a.93.93,0,0,1,.3-.65.83.83,0,0,1,.63-.23.85.85,0,0,1,.75.53.31.31,0,0,1,0,.28A.27.27,0,0,1,10.3,2a.25.25,0,0,1-.2-.16.4.4,0,0,0-.34-.22.37.37,0,0,0-.28.1.36.36,0,0,0,0,.52.37.37,0,0,0,.27.13.26.26,0,0,1,.24.28.25.25,0,0,1-.25.27Z"
          style={{fill: '#ecf0f1'}}
        />
        <path
          d="M9.69,4.11h0a.88.88,0,0,1-.75-.47.25.25,0,0,1,.45-.23.38.38,0,0,0,.33.2.36.36,0,0,0,.27-.09.35.35,0,0,0,.12-.23A.33.33,0,0,0,10,3.05a.45.45,0,0,0-.28-.13.23.23,0,0,1-.21-.13.23.23,0,0,1,0-.25.33.33,0,0,1,.23-.17.92.92,0,0,1,.62.35.8.8,0,0,1,.22.61.87.87,0,0,1-.88.78Z"
          style={{fill: '#ecf0f1'}}
        />
      </g>
      <path
        d="M11.15,4.11h0a.26.26,0,0,1-.22-.28l.19-2.52a.28.28,0,0,1,.14-.22.25.25,0,0,1,.25,0,.29.29,0,0,1,.1.25L11.4,3.87A.25.25,0,0,1,11.15,4.11Z"
        style={{fill: '#ecf0f1'}}
      />
      <path
        d="M12.5,4.45h0a.26.26,0,0,1-.2-.29l.24-2.52a.23.23,0,0,1,.24-.23.24.24,0,0,1,.2.29l-.23,2.52A.24.24,0,0,1,12.5,4.45Z"
        style={{fill: '#ecf0f1'}}
      />
      <path
        d="M12.57,3.1h0c-.41-.05-.82-.1-1.22-.13a.28.28,0,0,1-.22-.3.26.26,0,0,1,.26-.25l1.23.13a.28.28,0,0,1,.22.29A.27.27,0,0,1,12.57,3.1Z"
        style={{fill: '#ecf0f1'}}
      />
      <path
        d="M14.74,15H.26A.25.25,0,0,1,0,14.79a.23.23,0,0,1,.06-.27l1.61-1.31,1.22-1L4,11.34a.58.58,0,0,1,.34-.12h6.34a.58.58,0,0,1,.34.12l1.08.88,1.22,1,1.61,1.31a.23.23,0,0,1,.06.27A.25.25,0,0,1,14.74,15Z"
        style={{fill: '#5e5f62'}}
      />
      <path d="M5.65,11.22l-.72,1h-2l1.14-.9a.59.59,0,0,1,.35-.12Z" style={{fill: '#2c2f38'}} />
      <path d="M11.93,12.24h-2l-.73-1h1.25a.62.62,0,0,1,.35.12Z" style={{fill: '#2c2f38'}} />
      <polygon points="12.99 13.26 10.6 13.26 9.92 12.24 11.79 12.24 12.99 13.26" style={{fill: '#e6e7e8'}} />
      <polygon points="4.77 12.24 4.1 13.26 1.71 13.26 2.9 12.24 4.77 12.24" style={{fill: '#e6e7e8'}} />
      <polygon points="6.82 11.22 6.61 12.24 4.77 12.24 5.46 11.22 6.82 11.22" style={{fill: '#e6e7e8'}} />
      <polygon points="9.89 12.24 8.05 12.24 7.84 11.22 9.21 11.22 9.89 12.24" style={{fill: '#e6e7e8'}} />
      <polygon points="6.48 12.24 6.28 13.26 4.09 13.26 4.74 12.24 6.48 12.24" style={{fill: '#2c2f38'}} />
      <polygon points="8.18 12.24 6.48 12.24 6.7 11.22 7.96 11.22 8.18 12.24" style={{fill: '#2c2f38'}} />
      <polygon points="8.34 13.26 6.3 13.26 6.51 12.24 8.13 12.24 8.34 13.26" style={{fill: '#e6e7e8'}} />
      <polygon points="10.57 13.26 8.38 13.26 8.18 12.24 9.92 12.24 10.57 13.26" style={{fill: '#2c2f38'}} />
    </g>
  </Svg>
);

export const Start = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <defs>
      <clipPath id="a">
        <rect height="5.24" style={{fill: 'none'}} width="8.84" y="1.7" />
      </clipPath>
    </defs>
    <g>
      <g>
        <polygon points="1.68 6.7 1.68 13.3 0.77 13.3 0.77 6.6 1.68 6.7" style={{fill: '#49618c'}} />
        <polygon points="14.23 6.6 14.23 13.3 13.32 13.3 13.32 6.7 14.23 6.6" style={{fill: '#49618c'}} />
      </g>
      <path
        d="M15,1.7V6.44a57.73,57.73,0,0,1-6.29.5A56.41,56.41,0,0,1,0,6.44V1.7c.71.1,1.42.18,2.13.25h0A54.85,54.85,0,0,0,15,1.7Z"
        style={{fill: '#4ed315'}}
      />
      <g>
        <path d="M.77,6.6l.91.1v1L.77,7.58Z" style={{fill: '#3b4773'}} />
        <path d="M14.23,6.6v1l-.91.11v-1Z" style={{fill: '#3b4773'}} />
      </g>
      <g style={{clipPath: 'url(#a)'}}>
        <path
          d="M8.85,6.94A58.16,58.16,0,0,1,0,6.44V1.7c.72.1,1.44.18,2.17.25,0,0,1.93,4.68,6.67,5Z"
          style={{fill: '#43b910'}}
        />
      </g>
      <g>
        <path
          d="M6,3.93H6l-1.5-.08a.22.22,0,0,1-.21-.23.23.23,0,0,1,.25-.2c.49,0,1,.07,1.47.08a.24.24,0,0,1,.23.23A.23.23,0,0,1,6,3.93Z"
          style={{fill: '#fff'}}
        />
        <path
          d="M5.23,5.75A.22.22,0,0,1,5,5.54V3.68a.22.22,0,0,1,.23-.21.23.23,0,0,1,.24.21V5.54A.23.23,0,0,1,5.23,5.75Z"
          style={{fill: '#fff'}}
        />
        <path
          d="M11,3.81a.22.22,0,0,1-.23-.19A.22.22,0,0,1,11,3.38q.73-.06,1.47-.15a.23.23,0,0,1,.26.18.22.22,0,0,1-.2.24c-.49.06-1,.12-1.49.16Z"
          style={{fill: '#fff'}}
        />
        <path
          d="M11.71,5.59a.22.22,0,0,1-.23-.21V3.53a.23.23,0,0,1,.46,0V5.38A.22.22,0,0,1,11.71,5.59Z"
          style={{fill: '#fff'}}
        />
        <path
          d="M8.87,5a.23.23,0,0,1-.16-.06.19.19,0,0,1-.07-.15v-1a.22.22,0,0,1,.22-.21l.58,0a.74.74,0,0,1,.54.18.62.62,0,0,1,.22.48v.06a.76.76,0,0,1-.73.72L8.88,5Zm.23-1v.58h.34a.31.31,0,0,0,.3-.29V4.15A.21.21,0,0,0,9.66,4a.26.26,0,0,0-.19-.07Z"
          style={{fill: '#fff'}}
        />
        <path
          d="M8.87,5.79a.22.22,0,0,1-.23-.21V4.74a.23.23,0,0,1,.22-.22h.19a1.15,1.15,0,0,1,.82.29,1,1,0,0,1,.33.72.23.23,0,0,1-.46,0,.53.53,0,0,0-.19-.41.66.66,0,0,0-.45-.17v.64A.22.22,0,0,1,8.87,5.79Z"
          style={{fill: '#fff'}}
        />
        <path
          d="M7.83,5.81A.22.22,0,0,1,7.6,5.6V4.24A.3.3,0,0,0,7.3,4,.33.33,0,0,0,7.08,4,.25.25,0,0,0,7,4.23V5.59a.23.23,0,0,1-.46,0V4.23a.66.66,0,0,1,.22-.49.79.79,0,0,1,.55-.21.74.74,0,0,1,.76.71V5.6A.22.22,0,0,1,7.83,5.81Z"
          style={{fill: '#fff'}}
        />
        <path d="M7.5,5H6.76a.22.22,0,1,1,0-.43H7.83a.22.22,0,0,1,.23.21A.22.22,0,0,1,7.83,5Z" style={{fill: '#fff'}} />
        <path
          d="M3.18,5.58H3.09L2.52,5.5a.21.21,0,0,1-.2-.24.23.23,0,0,1,.26-.18l.56.07a.23.23,0,0,0,.17-.05A.18.18,0,0,0,3.38,5a.31.31,0,0,0-.29-.3H3a.78.78,0,0,1-.71-.74.58.58,0,0,1,.22-.46.69.69,0,0,1,.54-.15l.56.06a.22.22,0,0,1,.21.23.24.24,0,0,1-.26.19L3,3.71a.23.23,0,0,0-.18,0,.17.17,0,0,0-.06.14.32.32,0,0,0,.29.31h.06A.77.77,0,0,1,3.85,5a.6.6,0,0,1-.23.47A.7.7,0,0,1,3.18,5.58Z"
          style={{fill: '#fff'}}
        />
      </g>
    </g>
  </Svg>
);

export const QrCode = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M14.53,4.22a.47.47,0,0,1-.47-.47V1.35a.41.41,0,0,0-.41-.41h-2.4a.47.47,0,1,1,0-.94h2.4A1.34,1.34,0,0,1,15,1.35v2.4A.47.47,0,0,1,14.53,4.22Z" />
      <path d="M.47,4.22A.47.47,0,0,1,0,3.75V1.35A1.34,1.34,0,0,1,1.35,0h2.4a.47.47,0,0,1,0,.94H1.35a.41.41,0,0,0-.41.41v2.4A.47.47,0,0,1,.47,4.22Z" />
      <path d="M3.75,15H1.35A1.34,1.34,0,0,1,0,13.65v-2.4a.47.47,0,1,1,.94,0v2.4a.41.41,0,0,0,.41.41h2.4a.47.47,0,0,1,0,.94Z" />
      <path d="M13.65,15h-2.4a.47.47,0,0,1,0-.94h2.4a.41.41,0,0,0,.41-.41v-2.4a.47.47,0,0,1,.94,0v2.4A1.34,1.34,0,0,1,13.65,15Z" />
      <path d="M5.68,7H3.22A1.34,1.34,0,0,1,1.88,5.68V3.22A1.34,1.34,0,0,1,3.22,1.88H5.68A1.34,1.34,0,0,1,7,3.22V5.68A1.34,1.34,0,0,1,5.68,7ZM3.22,2.81a.42.42,0,0,0-.41.41V5.68a.41.41,0,0,0,.41.41H5.68a.41.41,0,0,0,.41-.41V3.22a.41.41,0,0,0-.41-.41Z" />
      <path d="M5.68,13.12H3.22a1.34,1.34,0,0,1-1.34-1.34V9.32A1.34,1.34,0,0,1,3.22,8H5.68A1.34,1.34,0,0,1,7,9.32v2.46A1.34,1.34,0,0,1,5.68,13.12ZM3.22,8.91a.41.41,0,0,0-.41.41v2.46a.42.42,0,0,0,.41.41H5.68a.41.41,0,0,0,.41-.41V9.32a.41.41,0,0,0-.41-.41Z" />
      <path d="M11.78,7H9.32A1.34,1.34,0,0,1,8,5.68V3.22A1.34,1.34,0,0,1,9.32,1.88h2.46a1.35,1.35,0,0,1,1.35,1.34V5.68A1.35,1.35,0,0,1,11.78,7ZM9.32,2.81a.41.41,0,0,0-.41.41V5.68a.41.41,0,0,0,.41.41h2.46a.41.41,0,0,0,.41-.41V3.22a.42.42,0,0,0-.41-.41Z" />
      <path d="M12.36,10.31H11.25V8.73A.76.76,0,0,0,10.49,8H8.73A.76.76,0,0,0,8,8.73v1.76a.76.76,0,0,0,.76.76h1.58v1.11a.76.76,0,0,0,.76.76h1.29a.77.77,0,0,0,.77-.76V11.07A.77.77,0,0,0,12.36,10.31ZM8.91,8.91h1.4v1.4H8.91Zm3.28,3.28h-.94v-.94h.94Z" />
      <path d="M4.69,5.16H4.22a.47.47,0,0,1-.47-.47V4.22a.47.47,0,0,1,.47-.47h.47a.47.47,0,0,1,.47.47v.47A.47.47,0,0,1,4.69,5.16Z" />
      <path d="M10.78,5.16h-.47a.47.47,0,0,1-.47-.47V4.22a.47.47,0,0,1,.47-.47h.47a.47.47,0,0,1,.47.47v.47A.47.47,0,0,1,10.78,5.16Z" />
      <path d="M4.69,11.25H4.22a.47.47,0,0,1-.47-.47v-.47a.47.47,0,0,1,.47-.47h.47a.47.47,0,0,1,.47.47v.47A.47.47,0,0,1,4.69,11.25Z" />
    </g>
  </Svg>
);
export const Play = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <circle cx="7.5" cy="7.5" r="7.5" style={{fill: '#ebba16'}} />
      <path
        d="M5.69,11.64a.27.27,0,0,1-.12,0,.25.25,0,0,1-.14-.23V3.62a.25.25,0,0,1,.14-.23.26.26,0,0,1,.27,0l5.69,3.88a.24.24,0,0,1,.11.21.22.22,0,0,1-.12.21L5.84,11.59A.3.3,0,0,1,5.69,11.64Z"
        style={{fill: '#fff'}}
      />
    </g>
  </Svg>
);

export const Trip = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M4.76,2.38a1.2,1.2,0,1,0-1.2,1.21A1.2,1.2,0,0,0,4.76,2.38Zm-1.82,0a.62.62,0,0,1,1.24,0,.62.62,0,0,1-1.24,0Z" />
      <path d="M11.64,7.68a3,3,0,0,0-1.78.48l-1-.23a.29.29,0,0,0-.35.22.28.28,0,0,0,.22.35l.81.18a.57.57,0,0,0,0,.18c0,.4.33.74.85,1l-.47.48a.3.3,0,0,0,0,.42.32.32,0,0,0,.2.08.27.27,0,0,0,.21-.09L11,10a3.69,3.69,0,0,0,.68,0c1.25,0,2.18-.5,2.18-1.18s-.93-1.18-2.18-1.18Zm0,1.78c-1,0-1.59-.36-1.59-.6a.27.27,0,0,1,.08-.16l0,0a2.37,2.37,0,0,1,1.48-.41c1,0,1.6.36,1.6.6S12.6,9.46,11.64,9.46Z" />
      <path d="M8.16,12.87l.47-.49a.29.29,0,0,0,0-.41.3.3,0,0,0-.41,0l-.69.72a3.85,3.85,0,0,0-.69-.06c-1.24,0-2.18.51-2.18,1.19S5.59,15,6.83,15,9,14.49,9,13.82c0-.4-.33-.74-.85-1ZM6.83,14.41c-1,0-1.6-.36-1.6-.59s.64-.6,1.6-.6,1.59.36,1.59.6S7.79,14.41,6.83,14.41Z" />
      <path d="M6.39,8h.07a.29.29,0,0,0,.28-.23.28.28,0,0,0-.22-.35l-.8-.18a1.1,1.1,0,0,0,0-.18c0-.55-.61-.92-1.31-1.09L5.74,3.36v0a2.23,2.23,0,0,0,.2-1,2.38,2.38,0,0,0-4.76,0,2.23,2.23,0,0,0,.2,1v0L2.69,6c-.7.17-1.31.54-1.31,1.08s.94,1.18,2.18,1.18a3,3,0,0,0,1.78-.48ZM1.76,2.38a1.8,1.8,0,1,1,3.45.72L3.56,6.4,1.91,3.1A1.79,1.79,0,0,1,1.76,2.38ZM2,7.06c0-.15.39-.42,1-.54l.34.67a.29.29,0,0,0,.52,0l.34-.68c.62.12,1,.39,1,.55a.24.24,0,0,1-.08.16l0,0a2.38,2.38,0,0,1-1.49.41c-1,0-1.6-.36-1.6-.6Z" />
      <path d="M9.24,11.05a.29.29,0,1,0,0,.58.29.29,0,0,0,0-.58Z" />
      <path d="M7.6,8.25A.29.29,0,1,0,7.31,8,.29.29,0,0,0,7.6,8.25Z" />
    </g>
  </Svg>
);

export const Pause = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <circle cx="7.5" cy="7.5" r="7.5" style={{fill: '#e56353'}} />
      <path
        d="M6,4.29H4.27a.21.21,0,0,0-.22.22v6.7a.21.21,0,0,0,.22.22H6a.21.21,0,0,0,.22-.22V4.51A.21.21,0,0,0,6,4.29Z"
        style={{fill: '#d15241'}}
      />
      <path
        d="M6,3.57H4.27a.21.21,0,0,0-.22.22v6.7a.21.21,0,0,0,.22.22H6a.21.21,0,0,0,.22-.22V3.79A.21.21,0,0,0,6,3.57Z"
        style={{fill: '#fff'}}
      />
      <path
        d="M10.73,4.29H9a.21.21,0,0,0-.22.22v6.7a.21.21,0,0,0,.22.22h1.7a.21.21,0,0,0,.22-.22V4.51A.21.21,0,0,0,10.73,4.29Z"
        style={{fill: '#d15241'}}
      />
      <path
        d="M10.73,3.57H9a.21.21,0,0,0-.22.22v6.7a.21.21,0,0,0,.22.22h1.7a.21.21,0,0,0,.22-.22V3.79A.21.21,0,0,0,10.73,3.57Z"
        style={{fill: '#fff'}}
      />
    </g>
  </Svg>
);

export const Flip = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" viewBox="0 0 15 15" width="100%">
    {children}
    <g>
      <path d="M2.07.53a.59.59,0,0,0,.53.64l.85.08a7.39,7.39,0,0,0,.62,12.86.59.59,0,0,0,.79-.26.59.59,0,0,0-.26-.79A6.22,6.22,0,0,1,4.08,2.23l-.59.83a.59.59,0,0,0,.13.82.58.58,0,0,0,.82-.14L6.25,1.21A.58.58,0,0,0,5.83.29L2.71,0A.59.59,0,0,0,2.07.53Z" />
      <path d="M10.14,1.15a.59.59,0,0,0,.26.79,6.22,6.22,0,0,1,.52,10.83l.59-.83a.59.59,0,0,0-.13-.82.58.58,0,0,0-.82.14L8.75,13.79a.58.58,0,0,0,.42.92l3.12.29a.59.59,0,0,0,.11-1.17l-.85-.08A7.39,7.39,0,0,0,10.93.89.59.59,0,0,0,10.14,1.15Z" />
    </g>
  </Svg>
);

export const VisibleEye = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" version="1.1" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    {children}
    <g>
      <path
        d="M 7.5 3.75 C 10.097656 3.75 12.597656 5 15 7.5 L 14.789062 7.71875 C 12.449219 10.074219 10.019531 11.25 7.5 11.25 C 4.902344 11.25 2.402344 10 0 7.5 L 0.210938 7.28125 C 2.550781 4.925781 4.980469 3.75 7.5 3.75 Z M 7.5 5 C 6.121094 5 5 6.121094 5 7.5 C 5 8.878906 6.121094 10 7.5 10 C 8.878906 10 10 8.878906 10 7.5 C 10 6.121094 8.878906 5 7.5 5 Z M 7.5 5.625 C 8.535156 5.625 9.375 6.464844 9.375 7.5 C 9.375 8.535156 8.535156 9.375 7.5 9.375 C 6.464844 9.375 5.625 8.535156 5.625 7.5 C 5.625 6.464844 6.464844 5.625 7.5 5.625 Z M 7.5 5.625 "
        style={{stroke: 'none', fillRule: 'evenodd', fill: 'rgb(24.313725%,53.333333%,94.509804%)', fillOpacity: 1}}
      />
    </g>
  </Svg>
);

export const HiddenEye = ({className, children}: PropsWithChildren<SvgIconProps>) => (
  <Svg className={className} height="100%" version="1.1" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    {children}
    <g>
      <path
        d="M 11.726562 4.902344 C 12.835938 5.519531 13.929688 6.386719 15 7.5 L 14.789062 7.71875 C 12.449219 10.074219 10.019531 11.25 7.5 11.25 C 6.863281 11.25 6.230469 11.175781 5.605469 11.023438 Z M 7.5 3.75 C 8.523438 3.75 9.535156 3.945312 10.527344 4.332031 L 9.199219 5.664062 C 8.75 5.253906 8.15625 5 7.5 5 C 6.121094 5 5 6.121094 5 7.5 C 5 8.15625 5.253906 8.75 5.664062 9.195312 L 4.277344 10.585938 C 2.816406 9.976562 1.394531 8.949219 0 7.5 L 0.210938 7.28125 C 2.550781 4.925781 4.980469 3.75 7.5 3.75 Z M 7.5 5.625 C 7.984375 5.625 8.421875 5.808594 8.753906 6.105469 L 6.105469 8.753906 C 5.808594 8.421875 5.625 7.984375 5.625 7.5 C 5.625 6.464844 6.464844 5.625 7.5 5.625 Z M 7.5 5.625 "
        style={{stroke: 'none', fillRule: 'evenodd', fill: 'rgb(24.313725%,53.333333%,94.509804%)', fillOpacity: 1}}
      />
      <path
        d="M 13.703125 1.167969 C 13.945312 1.410156 13.945312 1.808594 13.703125 2.050781 L 2.210938 13.542969 C 1.96875 13.785156 1.574219 13.785156 1.328125 13.542969 C 1.085938 13.296875 1.085938 12.902344 1.328125 12.65625 L 12.820312 1.167969 C 13.0625 0.921875 13.457031 0.921875 13.703125 1.167969 Z M 13.703125 1.167969 "
        style={{stroke: 'none', fillRule: 'evenodd', fill: 'rgb(24.313725%,53.333333%,94.509804%)', fillOpacity: 1}}
      />
    </g>
  </Svg>
);
