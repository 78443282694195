/* eslint-disable prettier/prettier */
import './App.css';
import {LoginDataProvider} from './data-providers/LoginDataProvider';
import {VehicleListDataProvider} from './data-providers/VehicleListDataProvider';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {MainPageDataProvider} from './data-providers/MainPageDataProvider';
import {PrivateRoute} from './components/PrivateRoute';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getToken} from './managers/slices/authenticationSlice';
import {LoaderPage} from './pages/LoaderPage';

function App() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const token = useSelector(getToken);

  useEffect(() => {
    setDataLoaded(true);
  }, [token]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {dataLoaded ? (
        <Routes>
          <Route key="Login" element={<LoginDataProvider />} path="/login" />
          <Route
            key="vehicles"
            element={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <PrivateRoute>
                <MainPageDataProvider title="test">
                  <VehicleListDataProvider />
                </MainPageDataProvider>
              </PrivateRoute>
            }
            path="/vehicles"
          />
          <Route key="initialRoute" element={<Navigate to="/vehicles" />} path="*" />
        </Routes>
      ) : (
        <LoaderPage />
      )}
    </BrowserRouter>
  );
}

export default App;
