import {BaseUrl} from '../constants';
import {Service, ServiceCodec} from '../models/Service';
import {AbstractAdapter} from './AbstractAdapter';

export class ServiceAdapter extends AbstractAdapter {
  static getService(name: string, service: Service, token: string) {
    const body = {name};

    return this.fetchJson(service, `${BaseUrl}/service`, ServiceCodec, {
      method: 'POST',
      headers: this.RequestHeaders(service, token),
      body: JSON.stringify(body),
    });
  }
}
