import styled from 'styled-components';
import {TextElement} from './TextElement';

export const Title = ({children}: {children: React.ReactNode}) => {
  return <Title.Default>{children}</Title.Default>;
};

Title.Default = styled(TextElement.Medium)`
  font-size: 1.25rem;
  color: #222222;
`;

Title.Normal = styled(Title.Default)`
  font-size: 1.5rem;
`;

Title.Medium = styled(Title.Default)`
  font-size: 1.75rem;
`;
