import assert from 'assert';
import React from 'react';
import styled, {css} from 'styled-components';
import {useBreakPoint, useBreakPoints} from '../hooks/useBreakPoint';

export interface IBreakPoints {
  xxs: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const breakpoints: IBreakPoints = {
  xxs: 360,
  xs: 481, // Landscape phones and down
  sm: 768, // Landscape phone to portrait tablet
  md: 960, // Portrait tablet to landscape and desktop
  lg: 1200, // Large desktop
  xl: 1400, // Huge monitors
};

export const respondTo: {
  [k in keyof IBreakPoints]: (...args: [any]) => any;
} = Object.keys(breakpoints).reduce((media, label) => {
  media[label] = (...args: [any]) => css`
    @media screen and (max-width: ${breakpoints[label as keyof IBreakPoints]}px) {
      ${css(...args)};
    }
  `;
  return media;
}, {} as any);

export const respondToTree: {
  [k in keyof IBreakPoints]: (template: TemplateStringsArray, ...interpolations: any[]) => any;
} = Object.keys(breakpoints).reduce((media, label) => {
  media[label] = (template: TemplateStringsArray, ...interpolations: any[]) => {
    let result = '';

    template.forEach((t, i) => {
      const className = interpolations[i] ? interpolations[i].styledComponentId : '';
      result += `${t}\n${interpolations[i - 1] ? '}' : ''}\n${
        className ? `.${className}{ \n@media screen and (max-width: ${breakpoints[label as keyof IBreakPoints]}px)` : ''
      }`;
    });

    return result;
  };
  return media;
}, {} as any);

type BreakPointSwitcherProps = React.PropsWithChildren<{
  className?: string;
  breakPoint: keyof IBreakPoints;
  wrapper?: React.ElementType | [React.ElementType, React.ElementType];
  verifyChildren?: boolean;
}>;

/*
  NB: The first child will be rendered if the
  condition of the breakpoint is satisfied (screen width < breakpoint width)
  otherwise the second child will be rendered
*/
export const BreakPointSwitcher = ({
  breakPoint,
  className,
  children,
  wrapper,
  verifyChildren = true,
}: BreakPointSwitcherProps) => {
  const valid = useBreakPoint(breakPoint);

  // If there is more than one child then
  // the first one is for the satisfied breakpoint
  // the second one is not for satisfied breakpoint
  const result = verifyChildren
    ? valid
      ? children && Array.isArray(children) && children.length > 1
        ? children[0]
        : children
      : children && Array.isArray(children) && children.length > 1
      ? children[1]
      : null
    : children;

  // Same as children, but with the wrapper
  const Wrapper = Array.isArray(wrapper) ? (valid ? wrapper[0] : wrapper[1]) : wrapper;

  return Wrapper ? (
    <Wrapper className={className}>{result}</Wrapper>
  ) : className ? (
    // In case this is used with styled, then we have a default wrapper
    <div className={className}>{result}</div>
  ) : (
    <>{result}</>
  );
};

export const MobileSwitcher = (props: Omit<BreakPointSwitcherProps, 'breakPoint'>) => {
  return BreakPointSwitcher({...props, breakPoint: 'md'});
};

type BreakPointsSwitcherProps = React.PropsWithChildren<{
  className?: string;
  breakPoints: (keyof IBreakPoints)[];
  wrapper?: React.ElementType | React.ElementType[];
  verifyChildren?: boolean;
}>;

const StyledDiv = styled.div``;

// This can be used to render multiple breakPoints at once
// using children or wrapper as conjunction
export const BreakPointsSwitcher = ({
  breakPoints,
  className,
  children,
  wrapper,
  verifyChildren = true,
}: BreakPointsSwitcherProps) => {
  const [validBreakPoint] = useBreakPoints(...breakPoints);

  // We should have wrapper.length === breakPoints.length + 1
  // or children.length === breakPoints.length + 1
  assert(
    (verifyChildren && breakPoints.length + 1 === (Array.isArray(children) ? children.length : 0)) ||
      breakPoints.length + 1 === (Array.isArray(wrapper) ? wrapper.length : 0),
    // eslint-disable-next-line max-len
    'Cannot use BreakPointsSwitcher: wrapper.length or children.length must be equals to breakpoints.length + 1 (1 for default)',
  );

  let Wrapper: React.ElementType = className === undefined ? React.Fragment : StyledDiv;
  let result: React.ReactNode = [];
  let index = breakPoints.length;

  // We have a validBreakPoint
  // (we take the first one valid starting from the smaller one)
  if (validBreakPoint) index = breakPoints.findIndex((bP) => bP === validBreakPoint);

  if (wrapper) {
    if (Array.isArray(wrapper)) Wrapper = wrapper[index];
    else Wrapper = wrapper;
  }

  if (children) {
    if (Array.isArray(children) && verifyChildren) result = children[index];
    else result = children;
  }

  return <Wrapper className={className}>{result}</Wrapper>;
};
