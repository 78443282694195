import React, {PropsWithChildren} from 'react';
import {useDispatch} from 'react-redux';
import {clearState} from '../managers/slices/authenticationSlice';

interface MainPageDataProviderProps extends PropsWithChildren<any> {
  title: string;
}

export const MainPageDataProvider = ({title, children}: MainPageDataProviderProps) => {
  const dispatch = useDispatch();
  return (
    <>
      <h1>Page {title}</h1>
      {children}
      <button onClick={() => dispatch(clearState())}>Logout</button>
    </>
  );
};
