import * as io from 'io-ts';
import {BaseServerTypeCodec} from './BaseServerType';
import {SiteArrayCodec} from './Site';

const Service = io.intersection([
  BaseServerTypeCodec,
  io.type({name: io.string, token: io.string, Sites: SiteArrayCodec, domain: io.string}),
]);

export const ServiceCodec = Service;

export type Service = io.TypeOf<typeof Service>;

export enum ServiceName {
  None = 'none',
  Movo = 'movo',
  Kirrk = 'kirrk',
  Govolt = 'govolt',
  Ride = 'ride',
  Egap = 'e-gap',
}
