import React from 'react';
import {Form} from 'react-bootstrap';
import styled from 'styled-components';
import {Button} from './buttons/Button';
import {Card as DefaultCard} from './Card';

export enum LoginFormValueType {
  Email,
  CompanyName,
  Password,
  RememberMe,
}

type EmailFormValueType = GenericFormValueType<LoginFormValueType.Email, string>;
type CompanyNameFormValueType = GenericFormValueType<LoginFormValueType.CompanyName, string>;
type PasswordFormValueType = GenericFormValueType<LoginFormValueType.Password, string>;
type RememberMeFormValueType = GenericFormValueType<LoginFormValueType.RememberMe, boolean>;

interface GenericFormValueType<K extends LoginFormValueType, T> {
  type: K;
  value: T;
}

export type LoginFormValueTypes =
  | EmailFormValueType
  | PasswordFormValueType
  | CompanyNameFormValueType
  | RememberMeFormValueType;

interface LoginFormProps {
  isLoading: boolean;
  isMobile: boolean;
  onFormSubmit: (event: React.FormEvent, values: LoginFormValueTypes[]) => void;
}

export const LoginForm = ({isMobile, isLoading, onFormSubmit: handleOnFormSubmit}: LoginFormProps) => {
  return isMobile ? (
    <LoginFormMobile isLoading={isLoading} onFormSubmit={handleOnFormSubmit} />
  ) : (
    <LoginFormDesktop isLoading={isLoading} onFormSubmit={handleOnFormSubmit} />
  );
};

const LoginFormDesktop = ({isLoading, onFormSubmit: handleOnFormSubmit}: Omit<LoginFormProps, 'isMobile'>) => {
  return (
    <Card>
      <DefaultCard.Title>
        <h1>Sign in to your account</h1>
      </DefaultCard.Title>
      <CardBody>
        <FormStyled
          autoComplete="off"
          onSubmit={(e: any) => {
            handleOnFormSubmit(e, [
              {type: LoginFormValueType.CompanyName, value: e.target.elements.companyName?.value},
              {type: LoginFormValueType.Email, value: e.target.elements.email?.value},
              {type: LoginFormValueType.Password, value: e.target.elements.password?.value},
              {type: LoginFormValueType.RememberMe, value: e.target.elements['remember-me-check']?.checked},
            ]);
          }}
        >
          <Form.Group className="mb-3" controlId="formCompanyName">
            <FormLabel>Company name*</FormLabel>
            <Form.Control name="companyName" required={true} type="text" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <FormLabel>Email address*</FormLabel>
            <Form.Control name="email" required={true} type="email" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPassword">
            <FormLabel>Password*</FormLabel>
            <Form.Control name="password" required={true} type="password" />
          </Form.Group>
          <FormCheck id="remember-me-check" name="remember-me-check" type="checkbox">
            <Form.Check.Input type="checkbox" />
            <Form.Check.Label style={{marginLeft: '10px', fontSize: '1rem'}}>Remember me</Form.Check.Label>
          </FormCheck>
          <SubmitButton isLoading={isLoading} type="submit">
            Sign in
          </SubmitButton>
        </FormStyled>
      </CardBody>
    </Card>
  );
};

const LoginFormMobile = ({isLoading, onFormSubmit: handleOnFormSubmit}: Omit<LoginFormProps, 'isMobile'>) => {
  return (
    <div>
      <FormStyledMobile
        autoComplete="off"
        onSubmit={(e: any) => {
          handleOnFormSubmit(e, [
            {type: LoginFormValueType.CompanyName, value: e.target.elements.companyName?.value},
            {type: LoginFormValueType.Email, value: e.target.elements.email?.value},
            {type: LoginFormValueType.Password, value: e.target.elements.password?.value},
            {type: LoginFormValueType.RememberMe, value: e.target.elements['remember-me-check']?.checked},
          ]);
        }}
      >
        <Form.Group className="mb-3" controlId="formCompanyName">
          <FormLabelMobile>Company name*</FormLabelMobile>
          <Form.Control name="companyName" placeholder="Insert company name" required={true} type="text" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formEmail">
          <FormLabelMobile>Email address*</FormLabelMobile>
          <Form.Control name="email" placeholder="Insert email" required={true} type="email" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPassword">
          <FormLabelMobile>Password*</FormLabelMobile>
          <Form.Control name="password" placeholder="Insert password" required={true} type="password" />
        </Form.Group>
        <FormCheck id="remember-me-check" name="remember-me-check" type="checkbox">
          <Form.Check.Input type="checkbox" />
          <Form.Check.Label style={{marginLeft: '10px', fontSize: '1rem', fontWeight: 'bold'}}>
            Remember me
          </Form.Check.Label>
        </FormCheck>
        <SubmitButton isLoading={isLoading} type="submit">
          Sign in
        </SubmitButton>
      </FormStyledMobile>
    </div>
  );
};

const Card = styled(DefaultCard)`
  ${DefaultCard.Body} {
    width: 30rem;
    padding: 40px;
    display: flex;
    flex-direction: column;
  }

  ${DefaultCard.Title} h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  color: #5a5a5a;
`;

const FormStyledMobile = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  color: white;
  width: 90vw;
`;

const FormLabel = styled(Form.Label)`
  font-size: 1rem;
`;

const FormLabelMobile = styled(Form.Label)`
  font-size: 1rem;
  font-weight: bold;
`;

const FormCheck = styled(Form.Check)`
  margin-bottom: 2rem;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
