import {RootUrl} from '../constants';
import {pipe} from 'fp-ts/function';
import * as io from 'io-ts';
import {fold, left, right} from 'fp-ts/Either';
import reporter from 'io-ts-reporters';
import {ResponseBody} from '../models/ResponseBody';
import {Service} from '../models/Service';

export abstract class AbstractAdapter {
  static RequestHeaders(service: Service, token: string): Headers {
    const headers: Headers = new Headers();

    headers.set('Content-Type', 'application/json; charset=utf-8');

    if (service && service.token) {
      headers.set('X-SERVICE-TOKEN', service.token);
    }

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  }

  static Hostname(service: Service): string {
    if (service.domain) {
      return `https://${service.domain}`;
    }

    return RootUrl;
  }

  protected static async fetchJson<C extends io.Mixed>(
    service: Service,
    url: string,
    validator: C,
    init?: RequestInit,
  ) {
    try {
      const response = await fetch(`${this.Hostname(service)}${url}`, init);
      const json = await response.json();

      const result = ResponseBody(validator).decode(json);

      return pipe(
        result,
        fold(
          () => {
            // TODO: implement a more comprehensive error reporter that iterates over
            // union types
            const messages = reporter.report(result);
            // TODO: print decoded object too
            return left(new Error(messages.join('\n')));
          },
          (value) => right(value),
        ),
      );
    } catch (err) {
      return Promise.reject(left(err));
    }
  }
}
