import {HTMLAttributes} from 'react';
import {Card as BootstrapCard} from 'react-bootstrap';
import styled from 'styled-components';
import {respondTo} from '../utils/StyleUtility';
import {Title} from './Title';

interface CardProps extends HTMLAttributes<HTMLDivElement> {}

export const Card = ({children, ...props}: CardProps) => {
  return (
    <BorderlessCard
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Card.Body>{children}</Card.Body>
    </BorderlessCard>
  );
};

const BorderlessCard = styled(BootstrapCard)`
  border: none;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

Card.Body = styled(BootstrapCard.Body)`
  padding: 1rem;
  border-radius: 12px;

  ${respondTo.md`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
  `}
`;

Card.Header = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

Card.Title = Title.Default;

Card.HeaderIcons = styled.div`
  display: flex;
  margin-left: auto;
`;

Card.IconWrapper = styled.div`
  cursor: pointer;
  margin-left: 0.5rem;

  svg {
    fill: #fff;
    height: 0.75rem;
    width: 0.75rem;
  }
`;
