import styled from 'styled-components';

export const TextElement = () => {
  return styled.div``;
};

TextElement.Default = styled.div`
  color: #000000;
`;

TextElement.Light = styled(TextElement.Default)`
  font-weight: 200;
`;

TextElement.Regular = styled(TextElement.Default)`
  font-weight: 400;
`;

TextElement.Medium = styled(TextElement.Default)`
  font-weight: 500;
`;

TextElement.Bold = styled(TextElement.Default)`
  font-weight: 800;
`;

TextElement.DefaultInverted = styled.div`
  color: #ffffff;
`;

TextElement.LightInverted = styled(TextElement.DefaultInverted)`
  font-weight: 200;
`;

TextElement.RegularInverted = styled(TextElement.DefaultInverted)`
  font-weight: 400;
`;

TextElement.MediumInverted = styled(TextElement.DefaultInverted)`
  font-weight: 500;
`;

TextElement.BoldInverted = styled(TextElement.DefaultInverted)`
  font-weight: 800;
`;
