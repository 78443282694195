/* eslint-disable react/destructuring-assignment */
import {useDispatch, useSelector} from 'react-redux';
import {ServiceAdapter} from '../adapters/ServiceAdapter';
import {LoginFormValueType, LoginFormValueTypes} from '../components/LoginForm';
import {LoginPage} from '../pages/LoginPage';
import {useNavigate} from 'react-router';
import {
  getStatus,
  getToken,
  setItemToState,
  setStatusToIdle,
  setStatusToLoading,
} from '../managers/slices/authenticationSlice';
import {fold} from 'fp-ts/Either';
import {pipe} from 'fp-ts/function';
import {LoginAdapter} from '../adapters/LoginAdapter';
import {Service} from '../models/Service';
import {useEffect, useState} from 'react';
import {ResponseBodyError} from '../models/ResponseBodyError';
import {Modal} from '../components/Modal';
import {TextElement} from '../components/TextElement';
import BackgroundImage from '../assets/Login.svg';
import {useMobile} from '../hooks/useBreakPoint';

export const LoginDataProvider = () => {
  // Redux
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const status = useSelector(getStatus);

  // Navigation
  const navigate = useNavigate();
  useEffect(() => {
    if (token && status === 'idle') {
      navigate('/vehicles');
    }
  }, [token, status, navigate]);

  // handle error
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState('');

  const handleError = (message: string, error?: ResponseBodyError | Error) => {
    setShowErrorAlert(true);
    setErrorAlertMessage(message);

    if (error) {
      console.error(error);
    }
  };

  const handleOnCloseAlert = () => {
    setShowErrorAlert(false);
  };

  const handleOnLoginSubmit = (values: LoginFormValueTypes[]) => {
    const [companyName, email, password, rememberMe] = values;
    if (
      companyName.type === LoginFormValueType.CompanyName &&
      email.type === LoginFormValueType.Email &&
      password.type === LoginFormValueType.Password &&
      rememberMe.type === LoginFormValueType.RememberMe
    ) {
      dispatch(setStatusToLoading());
      ServiceAdapter.getService(companyName.value, {} as Service, '')
        .then((response) =>
          pipe(
            response,
            fold(
              (e) => {
                handleError(e.message, e);
                return Promise.reject();
              },
              (s) => {
                if (s.data) {
                  return LoginAdapter.login(email.value, password.value, s.data, '')
                    .then((res) =>
                      pipe(
                        res,
                        fold(
                          (e) => {
                            handleError(e.message, e);
                          },
                          (ss) => {
                            if (ss.data) {
                              const {code: tk, User: user} = ss.data.token;
                              const data = {service: s.data, token: tk, user: user, rememberMe: rememberMe.value};
                              dispatch(setItemToState(data));
                            }
                            if (ss.error) {
                              handleError(ss.error.description, ss.error);
                            }
                          },
                        ),
                      ),
                    )
                    .catch((e) => {
                      handleError(e.message, e);
                    });
                }
                if (s.error) {
                  handleError(s.error.description, s.error);
                }
              },
            ),
          ),
        )
        .catch((e) => handleError(e.message, e))
        .finally(() => {
          dispatch(setStatusToIdle());
        });
    }
  };

  const isLoading = useSelector(getStatus) === 'loading';
  const isMobile = useMobile();

  return (
    <>
      <LoginPage
        backgroundImage={BackgroundImage}
        isLoading={isLoading}
        isMobile={isMobile}
        onLoginSubmit={handleOnLoginSubmit}
      />
      <Modal.Error show={showErrorAlert} title="Error" onHide={handleOnCloseAlert}>
        <TextElement.Medium data-test-id="login-error">{errorAlertMessage}</TextElement.Medium>
      </Modal.Error>
    </>
  );
};
