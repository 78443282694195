import * as io from 'io-ts';
import {BaseServerTypeCodec} from './BaseServerType';

const Site = io.intersection([
  BaseServerTypeCodec,
  io.type({
    name: io.string,
    topLatitude: io.number,
    bottomLatitude: io.number,
    leftLongitude: io.number,
    rightLongitude: io.number,
  }),
]);

export const SiteCodec = Site;
export const SiteArrayCodec = io.array(Site);

export type Site = io.TypeOf<typeof Site>;
export type SiteArray = io.TypeOf<typeof SiteArrayCodec>;
