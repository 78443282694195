import React from 'react';
import styled from 'styled-components';
import {Loader as DefaultLoader, withSpinningAnimation} from '../components/SvgIcon';

export const LoaderPage = styled(({className}: {className?: string}) => (
  <div className={className}>
    <Loader />
  </div>
))`
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = styled(withSpinningAnimation(DefaultLoader.Default))`
  width: 2rem;
  right: 2rem;
`;
