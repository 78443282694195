/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-comment-textnodes */
import styled, {css} from 'styled-components';
import {PropsWithChildren} from 'react';
import {Modal as BsModal, ModalProps as BsModalProps} from 'react-bootstrap';
import {Cross} from './SvgIcon';
import {TextElement} from './TextElement';
import {Button} from './buttons/Button';

export interface BaseModalProps extends BsModalProps {
  title?: string;
}

export const Modal = (props: PropsWithChildren<BaseModalProps>) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ModalStyled {...props} centered={true} size="lg">
      <ModalHeader closeButton={false}>
        <ModalTitle>
          <TextElement.LightInverted>{props.title}</TextElement.LightInverted>
        </ModalTitle>
        <CloseIconWrapper data-test-id="modal-close-button" onClick={props.onHide}>
          <CrossIcon />
        </CloseIconWrapper>
      </ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>
        <Button onClick={props.onHide}>Ok</Button>
      </ModalFooter>
    </ModalStyled>
  );
};

Modal.Error = (props: PropsWithChildren<BaseModalProps>) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ModalError {...props} />;
};

export const ModalHeader = styled(BsModal.Header)`
  border-bottom: none;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
`;

const ModalStyled = styled(BsModal)`
  .modal-dialog {
    max-width: 42.5rem;

    .modal-content {
      border-radius: 0.75rem;
      border: none;
    }
  }

  &.error {
    ${ModalHeader} {
      background-color: #ce0000;
    }
  }

  &.success {
    ${ModalHeader} {
      background-color: #0aa077;
    }
  }
`;

export const CrossIcon = styled(Cross)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;

  path  {
    fill: #ffffff;
  }
`;

export const CrossIconWrapper = styled.div`
  justify-self: flex-end;
  align-self: center;
  position: absolute;
  right: 1rem;
`;

export const ModalFooter = styled(BsModal.Footer)`
  border-top: none;
`;

export const ModalBody = styled(BsModal.Body)`
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
  text-align: center;
`;

const ButtonStyle = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  border: none;
  min-width: 6.25rem;
  display: inline-block;
`;

export const ConfirmButton = styled(Button)`
  ${ButtonStyle}
`;

export const CancelButton = styled(Button.Light)`
  ${ButtonStyle}
`;

export const ModalTitle = styled(BsModal.Title)`
  ${TextElement.LightInverted}, ${TextElement.Medium} {
    font-size: 1.25rem;
  }
`;

export const ModalError = styled(Modal)`
  ${ModalHeader} {
    background-color: #ce0000;
  }
`;

const CloseIconWrapper = styled.div`
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
  background-color: #d8d8d8;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 9999;

  svg {
    path {
      fill: #999999;
    }
  }
`;
