import {ElementType, PropsWithChildren} from 'react';
import {Button as BsButton, ButtonProps as BsButtonProps} from 'react-bootstrap';
import styled, {css} from 'styled-components';
import {Loader, withSpinningAnimation} from '../SvgIcon';
import {TextElement} from '../TextElement';

export interface ButtonProps extends BsButtonProps {
  isLoading?: boolean;
  innerComponent?: ElementType;
  shadowed?: boolean;
  primary?: boolean;
}

export const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {shadowed, primary, isLoading, children, innerComponent, ...buttonProps} = props;

  const ButtonComponent = shadowed ? (primary ? ShadowedPrimaryButton : ShadowedSecondaryButton) : ButtonStyled;
  const InnerComponent = innerComponent || (shadowed && !primary ? TextElement.Regular : TextElement.RegularInverted);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ButtonComponent {...buttonProps}>
      {isLoading ? (
        <LoaderIconWrapper>
          <LoaderIconStyled />
        </LoaderIconWrapper>
      ) : (
        <InnerComponent>{children}</InnerComponent>
      )}
    </ButtonComponent>
  );
};

export const ButtonColorStyle = (primaryColor?: string, hoverColor?: string, textColor?: string) => css`
  &,
  &:disabled {
    background-color: ${() => primaryColor};
    border-color: ${() => primaryColor};
  }
  &:not(:disabled) {
    &:hover,
    &:active&:active,
    &:focus&:focus {
      background-color: ${() => hoverColor};
      border-color: ${() => hoverColor};
    }
  }

  &:not(:disabled)  {
    & {
      &:active&:focus  {
        outline: none;
        box-shadow: none;
      }
    }
  }

  ${() => (textColor ? `color: ${textColor}` : '')}
`;

ButtonColorStyle.Primary = ButtonColorStyle('hsl(215.2, 86.5%, 59.4%)', 'hsl(211, 100%, 42.5%)', '#fff');
ButtonColorStyle.Light = ButtonColorStyle('hsl(0, 0%, 90%)', 'hsl(0, 0%, 95%)');
ButtonColorStyle.Dark = ButtonColorStyle('hsl(0, 0%, 50%)', 'hsl(0, 0%, 45%)');
ButtonColorStyle.Danger = ButtonColorStyle('hsl(0, 100%, 40%)', 'hsl(0, 100%, 35%)');
ButtonColorStyle.Success = ButtonColorStyle('hsl(163.6, 88%, 33%)', 'hsl(163.6, 88%, 28%)');

export const ButtonStyled = styled(BsButton)`
  &:focus&:focus {
    outline: none;
    box-shadow: none;
  }

  ${ButtonColorStyle.Primary}
`;

const ShadowedButtonStyle = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  border: none;
  min-width: 6.25rem;
  display: inline-block;
`;

const ShadowedPrimaryButton = styled(Button)`
  ${ShadowedButtonStyle}

  &,
  &:hover,
  &:active&:active {
    background-color: #3e88f1;
  }
`;

const ShadowedSecondaryButton = styled(Button)`
  ${ShadowedButtonStyle}

  &,
  &:hover,
  &:active&:active {
    background-color: #e9e9e9;
  }
`;

const LoaderIconWrapper = styled.div`
  display: flex;
  min-height: 1.5rem;
  justify-content: center;
  align-items: center;
`;

const LoaderIconStyled = styled(withSpinningAnimation(Loader.Lighter))`
  height: 1.25rem;
  width: 1.25rem;
`;

Button.Default = styled(Button)``;

Button.Color = styled(Button)<{primaryColor?: string; hoverColor?: string}>`
  ${({primaryColor, hoverColor}) => ButtonColorStyle(primaryColor, hoverColor)}
`;

Button.Light = styled(Button).attrs((p) => ({innerComponent: TextElement.Default, ...p, theme: undefined}))`
  ${ButtonColorStyle.Light}
`;

Button.Dark = styled(Button).attrs((p) => ({innerComponent: TextElement.DefaultInverted, ...p, theme: undefined}))`
  ${ButtonColorStyle.Dark}
`;

Button.Danger = styled(Button).attrs((p) => ({innerComponent: TextElement.DefaultInverted, ...p, theme: undefined}))`
  ${ButtonColorStyle.Danger}
`;

Button.Success = styled(Button).attrs((p) => ({innerComponent: TextElement.DefaultInverted, ...p, theme: undefined}))`
  ${ButtonColorStyle.Success}
`;

export const withLargeVariant = (button: typeof Button) => styled(button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 24%;
  min-width: 10rem;
  margin-top: 1rem;
`;
