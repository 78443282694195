import {configureStore} from '@reduxjs/toolkit';
import authenticationReducer, {
  clearStateMiddleware,
  setItemToStateMiddleware,
} from './managers/slices/authenticationSlice';

let preloadedState;
const persistedAuthenticationDataString =
  sessionStorage.getItem('app_data') ?? localStorage.getItem('app_data') ?? null;
// const persistedStateString = ...getItem('state);

if (persistedAuthenticationDataString) {
  preloadedState = {
    authentication: JSON.parse(persistedAuthenticationDataString),
    // if more states needed just load them from the session/local storage above.
  };
}

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(clearStateMiddleware)
      .concat(setItemToStateMiddleware),
  preloadedState: preloadedState,
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
