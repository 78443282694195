import {BaseUrl} from '../constants';
import {LoginResponseDataCodec} from '../models/LoginResponseData';
import {Service} from '../models/Service';
import {AbstractAdapter} from './AbstractAdapter';

export class LoginAdapter extends AbstractAdapter {
  static async login(username: string, password: string, service: Service, token: string) {
    const body = {username, password};

    return this.fetchJson(service, `${BaseUrl}/login`, LoginResponseDataCodec, {
      method: 'POST',
      headers: this.RequestHeaders(service, token),
      body: JSON.stringify(body),
    });
  }
}
