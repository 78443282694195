import * as io from 'io-ts';
import {DateFromUnixTime} from 'io-ts-types/lib/DateFromUnixTime';
import {BaseServerTypeCodec} from './BaseServerType';
import {UserCodec} from './User';

const Token = io.intersection([
  BaseServerTypeCodec,
  io.type({code: io.string, expire: DateFromUnixTime, User: UserCodec}),
]);

export const TokenCodec = Token;

export type Token = io.TypeOf<typeof Token>;
